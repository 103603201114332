import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import {
  Box, Typography, Button, Grid, Card, CardContent, IconButton, TextField, Modal, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const Profile = () => {
  const { logout } = useContext(AuthContext);
  const [profileData, setProfileData] = useState(null);
  const [patients, setPatients] = useState([]);
  const [news, setNews] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [showSubscriptions, setShowSubscriptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showNews, setShowNews] = useState(false);
  const [newNews, setNewNews] = useState({ title: '', description: '', image: null });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch('https://confidental1.com/api/users/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          localStorage.removeItem('token');
          navigate('/login');
          return;
        }

        const data = await response.json();
        setProfileData(data);
      } catch (error) {
        console.error('Error al obtener el perfil:', error);
        navigate('/login');
      }
    };

    const fetchPatients = async () => {
      try {
        const response = await fetch('https://confidental1.com/api/patients', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        setPatients(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error al obtener los pacientes:', error);
        setPatients([]);
      }
    };

    const fetchNews = async () => {
      try {
        const response = await fetch('https://confidental1.com/api/news', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        setNews(data);
      } catch (error) {
        console.error('Error al obtener las noticias:', error);
      }
    };

    const fetchSubscriptions = async () => {
      try {
        const response = await fetch('https://confidental1.com/api/subscriptions', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        setSubscriptions(data.reverse()); // Ordena las suscripciones desde la más reciente
      } catch (error) {
        console.error('Error al obtener las suscripciones:', error);
      }
    };

    fetchProfileData();
    fetchPatients();
    fetchNews();
    fetchSubscriptions();
  }, [navigate]);

  const handleOpenDialog = (patientId) => {
    setSelectedPatientId(patientId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPatientId(null);
  };

  const handleDeletePatient = async () => {
    try {
      await fetch(`https://confidental1.com/api/patients/${selectedPatientId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setPatients(patients.filter(patient => patient._id !== selectedPatientId));
      handleCloseDialog(); // Cierra el diálogo
    } catch (error) {
      console.error('Error al eliminar el paciente:', error);
    }
  };

  const handleDeleteNews = async (newsId) => {
    try {
      const response = await fetch(`https://confidental1.com/api/news/${newsId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        setNews(news.filter(n => n._id !== newsId));
      } else {
        const data = await response.json();
        alert('Error: ' + data.error);
      }
    } catch (error) {
      console.error('Error al eliminar la noticia:', error);
    }
  };

  const handleAddNews = async () => {
    const formData = new FormData();
    formData.append('title', newNews.title);
    formData.append('description', newNews.description);
    formData.append('image', newNews.image);

    try {
      const response = await fetch('https://confidental1.com/api/news', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (response.ok) {
        alert('Noticia agregada con éxito');
        setIsModalOpen(false);
        setNewNews({ title: '', description: '', image: null });
        const newNewsItem = await response.json();
        setNews([newNewsItem, ...news]); // Agregar la nueva noticia al principio del estado
      } else {
        const data = await response.json();
        alert('Error: ' + data.error);
      }
    } catch (error) {
      alert('Error al agregar la noticia: ' + error.message);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const filteredPatients = patients.filter((patient) =>
    patient.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    patient.age.toString().includes(searchTerm) ||
    patient.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
    patient.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (patient.observations && patient.observations.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  if (!profileData) {
    return <Typography>Cargando perfil...</Typography>;
  }

  const buttonStyles = {
    mt: 3,
    ml: 2,
    backgroundColor: 'transparent',
    color: '#1976d2',
    border: '2px solid #1976d2',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      transform: 'scale(1.05)',
    },
    '&:active': {
      backgroundColor: '#115293',
    },
    transition: 'all 0.3s ease',
    boxShadow: '0 3px 5px 2px rgba(25, 118, 210, .5)',
    borderRadius: '8px',
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4">Bienvenido, {profileData.name}</Typography>
      <Button variant="outlined" sx={{ ...buttonStyles }} onClick={() => navigate('/new-patient')}>Nuevo Paciente</Button>
      <Button variant="outlined" sx={{ ...buttonStyles, borderColor: '#d32f2f', color: '#d32f2f', '&:hover': { backgroundColor: '#d32f2f', color: '#ffffff' } }} onClick={logout}>Cerrar sesión</Button>
      <Button variant="outlined" sx={{ ...buttonStyles, borderColor: '#388e3c', color: '#388e3c', '&:hover': { backgroundColor: '#388e3c', color: '#ffffff' } }} onClick={() => setIsModalOpen(true)}>Agregar Noticia</Button>
      <Button variant="outlined" sx={{ ...buttonStyles }} onClick={() => setShowNews(!showNews)}>{showNews ? 'Ocultar Noticias' : 'Ver Noticias'}</Button>
      <Button variant="outlined" sx={{ ...buttonStyles }} onClick={() => setShowSubscriptions(!showSubscriptions)}>{showSubscriptions ? 'Ocultar Suscripciones' : 'Ver Suscripciones'}</Button>
      <Button variant="outlined" sx={{ ...buttonStyles }} onClick={() => navigate('/historia-clinica', { state: { doctorId: profileData._id } })}>Historia Clínica</Button>

      {/* Confirmación para eliminar paciente */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Estás seguro de que deseas eliminar este paciente? Esta acción no se puede deshacer.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancelar</Button>
          <Button onClick={handleDeletePatient} color="error">Eliminar</Button>
        </DialogActions>
      </Dialog>

      {/* Modal para agregar noticias */}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ bgcolor: 'white', p: 4, maxWidth: 500, width: '100%' }}>
          <Typography variant="h6" gutterBottom>Agregar Noticia</Typography>
          <TextField label="Título" variant="outlined" fullWidth sx={{ mb: 2 }} value={newNews.title} onChange={(e) => setNewNews({ ...newNews, title: e.target.value })} />
          <TextField label="Descripción" variant="outlined" fullWidth multiline rows={4} sx={{ mb: 2 }} value={newNews.description} onChange={(e) => setNewNews({ ...newNews, description: e.target.value })} />
          <Button variant="contained" component="label" fullWidth sx={{ mb: 2 }}>
            Subir Imagen
            <input type="file" hidden onChange={(e) => setNewNews({ ...newNews, image: e.target.files[0] })} />
          </Button>
          <Button variant="contained" color="primary" onClick={handleAddNews} fullWidth>Guardar Noticia</Button>
        </Box>
      </Modal>

      {/* Mostrar Noticias */}
      {showNews && (
        <>
          <Typography variant="h5" sx={{ mt: 4 }}>Noticias</Typography>
          {news.length === 0 ? (
            <Typography>No se encontraron noticias</Typography>
          ) : (
            <Box sx={{ mt: 2 }}>
              {news.map((newsItem) => (
                <Card key={newsItem._id} sx={{ mb: 2 }}>
                  <CardContent>
                    <Typography variant="h6">{newsItem.title}</Typography>
                    <Typography>{newsItem.description}</Typography>
                    {newsItem.image && (
                      <Box sx={{ mt: 2 }}>
                        <img src={`https://confidental1.com:5000${newsItem.image}`} alt={newsItem.title} style={{ width: '100%', height: 'auto' }} />
                      </Box>
                    )}
                    <Button variant="contained" color="error" sx={{ mt: 2 }} onClick={() => handleDeleteNews(newsItem._id)}>Eliminar Noticia</Button>
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}
        </>
      )}

      {/* Mostrar Suscripciones */}
      {showSubscriptions && (
        <>
          <Typography variant="h5" sx={{ mt: 4 }}>Suscripciones</Typography>
          {subscriptions.length === 0 ? (
            <Typography>No se encontraron suscripciones</Typography>
          ) : (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Número de Teléfono</TableCell>
                    <TableCell>Fecha de Suscripción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptions.map((subscription) => (
                    <TableRow key={subscription._id}>
                      <TableCell>{subscription.phoneNumber}</TableCell>
                      <TableCell>{new Date(subscription.subscribedAt).toLocaleDateString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}

      {/* Barra de búsqueda */}
      <TextField label="Buscar Pacientes" variant="outlined" fullWidth sx={{ mt: 4, mb: 4 }} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />

      <Typography variant="h5" sx={{ mt: 4 }}>Pacientes</Typography>
      {filteredPatients.length === 0 ? (
        <Typography>No se encontraron pacientes</Typography>
      ) : (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {filteredPatients.map((patient) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={patient._id}>
              <Card
                sx={{
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                  borderRadius: '16px',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.3)',
                  },
                  backgroundColor: '#f7f9fc',
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>{patient.name}</Typography>
                  <Typography variant="body2">Edad: {patient.age}</Typography>
                  <Typography variant="body2">Fecha de Nacimiento: {formatDate(patient.birthDate)}</Typography>
                  <Typography variant="body2">Dirección: {patient.address}</Typography>
                  <Typography variant="body2">Teléfono: {patient.phone}</Typography>
                  <Typography variant="body2">Observaciones: {patient.observations || 'N/A'}</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <IconButton onClick={() => navigate(`/view-images/${patient._id}`)} sx={{ color: '#1976d2' }}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => navigate(`/historia-clinica-personal/${patient._id}`)} sx={{ color: '#1976d2' }}>
                      <MenuBookIcon />
                    </IconButton>
                    <IconButton onClick={() => navigate(`/edit-patient/${patient._id}`)} sx={{ color: '#1976d2' }}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDialog(patient._id)} sx={{ color: '#d32f2f' }}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Profile;
