import React, { useState } from 'react';
import { Box, Button, Typography, Card, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';

const UploadFiles = () => {
  const { patientId } = useParams(); // Obtener el ID del paciente
  const [files, setFiles] = useState([]);
  const [isBaseImage, setIsBaseImage] = useState(false); // Estado para controlar si es imagen base
  const [selectedImage, setSelectedImage] = useState(null); // Mostrar imagen seleccionada
  const [textFields, setTextFields] = useState([]); // Campos de texto activados
  const [isEditing] = useState(false); // Controlar si se pueden agregar cuadros

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFiles([file]);
    const fileUrl = URL.createObjectURL(file);
    setSelectedImage(fileUrl); // Mostrar la imagen seleccionada
  };

  // Subir imagen para paciente o como imagen base
  const handleSubmit = async () => {
    if (!patientId && !isBaseImage) {
      alert('No se pudo obtener el ID del paciente.');
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('isBaseImage', isBaseImage); // Indicar si es imagen base
    formData.append('textFields', JSON.stringify(textFields)); // Enviar los campos de texto

    try {
      const endpoint = isBaseImage
        ? 'https://confidental1.com/api/patients/upload-base-image'
        : `https://confidental1.com/api/patients/${patientId}/upload`;

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (response.ok) {
        alert('Imagen subida con éxito');
        setIsBaseImage(false); // Resetear después de la subida
        setTextFields([]); // Limpiar los campos de texto
        setSelectedImage(null); // Limpiar la imagen seleccionada
      } else {
        const data = await response.json();
        alert('Error: ' + data.error);
      }
    } catch (error) {
      alert('Error en la solicitud: ' + error.message);
    }
  };

  // Agregar campos de texto al hacer clic en la imagen cuando está en modo de edición
  const handleAddTextField = (event) => {
    if (!isEditing) return; // Solo permitir cuando esté en modo edición

    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setTextFields([...textFields, { x, y, value: '' }]); // Agregar nuevo campo de texto
  };

  // Actualizar el texto del campo
  const handleTextFieldInput = (index, event) => {
    const newTextFields = [...textFields];
    newTextFields[index].value = event.target.value;
    setTextFields(newTextFields);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        bgcolor: '#f5f5f5',
        p: 3,
      }}
    >
      <Card sx={{ maxWidth: 400, width: '100%' }}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
            Subir Imagen
          </Typography>

          <Box sx={{ mt: 3 }}>
            <input type="file" onChange={handleFileChange} />

            {/* Botón para subir imagen para paciente */}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3 }}
              onClick={() => {
                setIsBaseImage(false);
                handleSubmit(); // Ejecutar la subida para paciente
              }}
            >
              Subir imagen
            </Button>
          </Box>

          {/* Mostrar imagen seleccionada */}
          {selectedImage && (
            <Box
              sx={{
                mt: 3,
                border: '1px solid #ccc',
                position: 'relative',
                display: 'inline-block',
              }}
              onClick={handleAddTextField} // Permitir agregar campos de texto al hacer clic en la imagen
            >
              <img src={selectedImage} alt="Vista previa" style={{ width: '100%' }} />

              {/* Mostrar los campos de texto */}
              {textFields.map((field, index) => (
                <input
                  key={index}
                  value={field.value}
                  onChange={(e) => handleTextFieldInput(index, e)}
                  style={{
                    position: 'absolute',
                    top: field.y,
                    left: field.x,
                    border: 'none',
                    backgroundColor: 'transparent',
                    color: 'black',
                  }}
                />
              ))}
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default UploadFiles;









