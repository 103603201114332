// src/components/Subscription.js
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const Subscription = () => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubscribe = async () => {
    try {
      const response = await fetch('https://confidental1.com/api/subscriptions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber }),
      });

      if (response.ok) {
        console.log('Número de teléfono suscrito');
        setPhoneNumber(''); // Limpiar el campo después de suscribirse
        alert('Te has suscrito con éxito');
      } else {
        const data = await response.json();
        alert('Error: ' + data.error);
      }
    } catch (error) {
      console.error('Error al suscribirse:', error);
      alert('Error al suscribirse.');
    }
  };

  return (
    <Box sx={{ bgcolor: '#0142A8', color: 'white', p: 4, textAlign: 'center', mt: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        Suscríbete a Promociones y Noticias
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Ingresa tu número de teléfono para recibir nuestras últimas ofertas y novedades.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: { xs: 'column', sm: 'row' },
          mt: 2,
          gap: 2,
        }}
      >
        <TextField
          label="Número de Teléfono"
          variant="outlined"
          fullWidth={true}
          sx={{
            bgcolor: 'white',
            borderRadius: 1,
            mb: { xs: 2, sm: 0 },
            width: { xs: 200, sm: 'auto' },
          }}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubscribe}
          sx={{
            width: { xs: 200, sm: 'auto' },
          }}
        >
          Suscribirse
        </Button>
      </Box>
    </Box>
  );
};

export default Subscription;



