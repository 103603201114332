import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useNavigate } from 'react-router-dom';

const Noticias = () => {
  const [newsItems, setNewsItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('https://confidental1.com/api/news');
        const data = await response.json();
        setNewsItems(data);
      } catch (error) {
        console.error('Error al obtener las noticias:', error);
      }
    };

    fetchNews();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/noticias/${id}`);
  };

  return (
    <Box sx={{ p: 3, textAlign: 'center' }}>
      <Typography variant="h4" component="h2" gutterBottom sx={{ color: 'black' }}>
        Noticias
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', mt: 2 }}>
        {newsItems.map((news, index) => (
          <Card
            key={news._id}
            sx={{ width: { xs: '100%', sm: 300 }, m: 2, textAlign: 'left', cursor: 'pointer' }}
            onClick={() => handleCardClick(news._id)}
          >
            <CardMedia
              component="img"
              height="140"
              image={`https://confidental1.com${news.image}`} // Asegúrate de que 'news.image' contiene la ruta correcta
              alt={news.title}
            />


            <CardContent>
              <Typography variant="h5" component="div" sx={{ color: 'black' }}>
                {news.title}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default Noticias;



