import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Subscription from './Subscription';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { keyframes } from '@mui/system';
import Citas from './Citas';

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Ortodoncia = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#e3f2fd', p: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            mb: 3,
          }}
        >
          <Box sx={{ flex: 1, textAlign: 'center', mb: { xs: 3, md: 0 } }}>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                color: '#1976d2',
                fontFamily: 'Roboto, sans-serif',
                animation: `${slideIn} 1s ease-out forwards`,
              }}
            >
              Ortodoncia
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: '#1976d2',
                fontFamily: 'Roboto, sans-serif',
                animation: `${fadeIn} 1.5s ease-out forwards`,
                opacity: 0,
              }}
            >
              "Patricia Moreno Servín"
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#424242',
                fontFamily: 'Arial, sans-serif',
                animation: `${fadeIn} 1.5s ease-out forwards`,
                opacity: 0,
                bgcolor: 'transparent',
                p: 2,
              }}
            >
              "La ortodoncia es la especialidad encargada de corregir dientes y mandíbulas desalineadas, mejorando no solo la estética de tu sonrisa sino también la función de tu mordida. Utilizamos tecnología de vanguardia para ofrecer tratamientos personalizados que alinean tus dientes de manera eficiente y cómoda, ayudando a prevenir problemas de salud oral a largo plazo."
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay>
              <div>
                <img
                  src="/dentistas.jpg"
                  alt="Ortodoncia Imagen 1"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </div>
              <div>
                <img
                  src="/10.jpg"
                  alt="Ortodoncia Imagen 2"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </div>
            </Carousel>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, bgcolor: 'white', p: 3 }}>
        <Citas initialSpecialization="Ortodoncia" />
      </Box>
      <Subscription />
    </Box>
  );
};

export default Ortodoncia;







