import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [pressTimer, setPressTimer] = useState(null);
  const [isLongPress, setIsLongPress] = useState(false); // Nueva bandera para controlar la presión prolongada
  const navigate = useNavigate(); // Hook para navegación

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };

  const handleAccordionToggle = (index) => (event, isExpanded) => {
    event.stopPropagation();
    setExpanded(isExpanded ? index : false);
  };

  const handleLogoClick = () => {
    if (!isLongPress) {
      navigate('/'); // Redirigir a la vista principal (Main.js) si no es una presión prolongada
    }
    setIsLongPress(false); // Resetear el estado
  };

  const handleLogoPressStart = () => {
    // Iniciar temporizador de 3 segundos
    const timer = setTimeout(() => {
      setIsLongPress(true); // Marcar como una presión prolongada
      navigate('/login'); // Redirigir a la vista de login
    }, 3000);
    setPressTimer(timer);
  };

  const handleLogoPressEnd = () => {
    // Cancelar temporizador si el usuario deja de presionar antes de tiempo
    clearTimeout(pressTimer);
  };

  const menuItems = [
    {
      title: 'Especialidades',
      submenu: [
        { name: 'Endodoncia', path: '/especialidades/endodoncia' },
        { name: 'Cirugía Maxilofacial', path: '/especialidades/cirugía maxilofacial' },
        { name: 'Odontopediatría', path: '/especialidades/odontopediatria' },
        { name: 'Ortodoncia', path: '/especialidades/ortodoncia' },
        { name: 'Rehabilitación e Implantología', path: '/especialidades/rehabilitación e implantologia' },
        { name: 'Odontología General', path: '/especialidades/odontologia general' }
      ]
    },
    { title: 'Citas', path: '/citas' },
    { title: 'Sobre Nosotros', path: '/sobre-nosotros' },
    { title: 'Noticias', path: '/noticias' }
  ];

  const drawerList = () => (
    <Box
      sx={{ width: 250, bgcolor: '#060E3C', height: '100%', color: 'white' }}
      role="presentation"
      onClick={(event) => {
        if (!event.target.closest('.MuiAccordion-root')) {
          toggleDrawer(false)(event);
        }
      }}
      onKeyDown={(event) => {
        if (!event.target.closest('.MuiAccordion-root')) {
          toggleDrawer(false)(event);
        }
      }}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem key={item.title} disablePadding>
            {item.submenu ? (
              <Accordion
                expanded={expanded === index}
                onChange={handleAccordionToggle(index)}
                sx={{ bgcolor: '#060E3C', color: 'white', boxShadow: 'none' }}
              >
                <AccordionSummary
                  expandIcon={expanded === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <ListItemText primary={item.title} />
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {item.submenu.map((subitem) => (
                      <ListItem key={subitem.name} disablePadding>
                        <ListItemButton component={Link} to={subitem.path}>
                          <ListItemText primary={subitem.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ) : (
              <ListItemButton component={Link} to={item.path}>
                <ListItemText primary={item.title} />
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" sx={{ bgcolor: '#060E3C' }}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, ml: 2 }}>
          <IconButton
            onClick={handleLogoClick} // Redirigir al Main.js cuando se hace clic
            onMouseDown={handleLogoPressStart} // Detectar cuando el usuario comienza a presionar
            onMouseUp={handleLogoPressEnd} // Detectar cuando el usuario deja de presionar
            onTouchStart={handleLogoPressStart} // Lo mismo para dispositivos táctiles
            onTouchEnd={handleLogoPressEnd}
            sx={{ p: 0 }}
          >
            <img
              src="/LOGO2.png"
              alt="Logo"
              width={40}
              height={40}
              style={{ transform: 'scale(4)', marginLeft: '50px' }} // Aumenta el tamaño y mueve el logo a la derecha
            />
          </IconButton>
        </Box>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {/* Título de la Aplicación */}
        </Typography>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
          {menuItems.map((item, index) => (
            <div key={item.title}>
              {item.submenu ? (
                <>
                  <Button
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={(event) => handleMenuClick(event, index)}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    endIcon={
                      openMenu === index ? <ExpandLessIcon /> : <ExpandMoreIcon />
                    }
                  >
                    {item.title}
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={openMenu === index}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'simple-menu-button',
                    }}
                  >
                    {item.submenu.map((subitem) => (
                      <MenuItem key={subitem.name} onClick={handleMenuClose} component={Link} to={subitem.path}>
                        {subitem.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <Button color="inherit" sx={{ ml: 2 }} component={Link} to={item.path}>
                  {item.title}
                </Button>
              )}
            </div>
          ))}
        </Box>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          sx={{ display: { xs: 'flex', md: 'none' } }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
    </AppBar>
  );
};

export default Header;






