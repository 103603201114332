import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Subscription from './Subscription';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import { useNavigate } from 'react-router-dom';

const comentarios = [
  { autor: "Lucero Martin", comentario: "El trato del doctor Eladio Robledo es excelente...", rating: 5 },
  { autor: "Mónica Martínez", comentario: "El trato hacia mi hijo fue excelente...", rating: 5 },
  { autor: "Efrain Rubio", comentario: "Excelente atención con el doctor Sergio...", rating: 5 }
];

const Main = () => {
  const [newsItems, setNewsItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('https://confidental1.com/api/news');
        const data = await response.json();
        setNewsItems(data);
      } catch (error) {
        console.error('Error al obtener las noticias:', error);
      }
    };

    fetchNews();
  }, []);

  const handleReviewClick = () => {
    window.open("https://www.google.com/maps/place/Confidental", "_blank");
  };

  const handleCardClick = (id) => {
    navigate(`/noticias/${id}`);
  };

  // Funciones para redirigir a cada especialidad
  const goToRehabilitation = () => {
    window.scrollTo(0, 0); // Desplazar al inicio de la página
    navigate('/especialidades/rehabilitación%20e%20implantologia');
  };
  const goToEndodontics = () => {
    window.scrollTo(0, 0); 
    navigate('/especialidades/endodoncia');
  };
  const goToStomatology = () => {
    window.scrollTo(0, 0); 
    navigate('/especialidades/odontologia%20general');
  };
  const goToMaxillofacial = () => {
    window.scrollTo(0, 0); 
    navigate('/especialidades/cirugia-maxilofacial');
  };
  const goToOdontopediatrics = () => {
    window.scrollTo(0, 0); 
    navigate('/especialidades/odontopediatria');
  };
  const goToOrthodontics = () => {
    window.scrollTo(0, 0); 
    navigate('/especialidades/ortodoncia');
  };
  

  return (
    <Box>
      {/* Sección Principal con Carrusel */}
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' }, 
          justifyContent: 'center', 
          alignItems: 'center', 
          p: { xs: 2, md: 3 } 
        }}
      >
        {/* Sección de Texto */}
        <Box 
          sx={{ 
            flex: 1, 
            mr: { md: 4 }, 
            mb: { xs: 3, md: 0 }, 
            textAlign: { xs: 'center', md: 'left' } 
          }}
        >
          <Typography variant="h4" component="h2" gutterBottom sx={{ color: 'black' }}>
            Bienvenido a Nuestro Consultorio
          </Typography>
          <Typography variant="body1" sx={{ color: 'black' }}>
            Aquí puedes encontrar una variedad de servicios y productos diseñados para ti...
          </Typography>
        </Box>

        {/* Sección del Carrusel */}
        <Box sx={{ flex: 1 }}>
          <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay>
            <div>
              <img src="/1.jpg" alt="Imagen 1" style={{ width: '100%', height: 'auto' }} />
            </div>
            <div>
              <img src="/2.jpg" alt="Imagen 2" style={{ width: '100%', height: 'auto' }} />
            </div>
            <div>
              <img src="/3.jpg" alt="Imagen 3" style={{ width: '100%', height: 'auto' }} />
            </div>
          </Carousel>
        </Box>
      </Box>

      {/* Nueva Sección con Imagen y Texto */}
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' }, 
          justifyContent: 'center', 
          alignItems: 'center', 
          p: { xs: 2, md: 3 }, 
          bgcolor: '#f5f5f5', 
          mt: 4 
        }}
      >
        {/* Imagen a la Izquierda */}
        <Box 
          sx={{ 
            flex: 1, 
            mr: { md: 3 }, 
            mb: { xs: 3, md: 0 } 
          }}
        >
          <img 
            src="/4.jpg" 
            alt="Sonrisa" 
            style={{ 
              width: '100%', 
              height: 'auto', 
              maxHeight: { xs: '300px', md: '400px', lg: '500px' } 
            }} 
          />
        </Box>

        {/* Texto a la Derecha */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h4" component="h2" sx={{ color: 'black', textAlign: { xs: 'center', md: 'left' } }}>
            Una sonrisa perfecta todos los días
          </Typography>
        </Box>
      </Box>

      {/* Sección de Especialidades - Imagen Única */}
      <Box 
        sx={{ 
          p: { xs: 2, md: 3 }, 
          textAlign: 'center', 
          mt: 4, 
          display: 'flex', 
          justifyContent: 'center' 
        }}
      >
        <Box 
          sx={{ 
            width: '100%', 
            maxWidth: { xs: '100%', sm: '75%', md: '50%' }, 
            overflow: 'hidden',
            display: 'flex', 
            justifyContent: 'center' 
          }}
        >
          <img 
            src="/ESP1.png" 
            alt="Áreas de Especialización" 
            useMap="#specialty-map"
            style={{ 
              width: '100%', 
              height: 'auto', 
              objectFit: 'contain' 
            }} 
          />
          <map name="specialty-map">
            <area 
              shape="rect" 
              coords="50,150,500,250"  // Cambia estas coordenadas según las áreas de la imagen
              alt="Rehabilitation"
              onClick={goToRehabilitation} 
            />
            <area 
              shape="rect" 
              coords="50,250,500,350" 
              alt="Endodontics"
              onClick={goToEndodontics} 
            />
            <area 
              shape="rect" 
              coords="50,350,500,450" 
              alt="Stomatology"
              onClick={goToStomatology} 
            />
            <area 
              shape="rect" 
              coords="50,450,500,550" 
              alt="Maxillofacial"
              onClick={goToMaxillofacial} 
            />
            <area 
              shape="rect" 
              coords="50,550,500,650" 
              alt="Odontopediatrics"
              onClick={goToOdontopediatrics} 
            />
            <area 
              shape="rect" 
              coords="50,650,500,750" 
              alt="Orthodontics"
              onClick={goToOrthodontics} 
            />
          </map>
        </Box>
      </Box>

      {/* Sección de Últimas Noticias */}
      <Box sx={{ p: { xs: 2, md: 3 }, textAlign: 'center', mt: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom sx={{ color: 'black' }}>
          Últimas Noticias
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', mt: 2 }}>
          {newsItems.length > 0 ? (
            newsItems.map((news) => (
              <Card 
                key={news._id} 
                sx={{ width: { xs: '100%', sm: 300 }, m: 2, textAlign: 'left', cursor: 'pointer' }}
                onClick={() => handleCardClick(news._id)}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={`https://confidental1.com${news.image}`} // Asegúrate de que la ruta de la imagen sea correcta
                  alt={news.title}
                />
                <CardContent>
                  <Typography variant="h5" component="div" sx={{ color: 'black' }}>
                    {news.title}
                  </Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>No hay noticias disponibles.</Typography>
          )}
        </Box>
      </Box>

      {/* Resto del contenido (Comentarios, Mapa, Suscripción, etc.) */}
      <Box sx={{ p: { xs: 2, md: 3 }, textAlign: 'center', mt: 4 }}>
        {/* Comentarios */}
        <Box sx={{ flex: 1, order: 1, p: 3 }}>
          {comentarios.map((comentario, index) => (
            <Card key={index} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {comentario.autor}
                </Typography>
                <Rating value={comentario.rating} readOnly />
                <Typography variant="body2" sx={{ color: 'gray' }}>
                  {comentario.comentario}
                </Typography>
              </CardContent>
            </Card>
          ))}
          <Button
            variant="contained"
            sx={{ mt: 2, bgcolor: '#0142A8' }}
            onClick={handleReviewClick}
          >
            Agregar Reseña
          </Button>
        </Box>

        {/* Mapa */}
        <Box sx={{ flex: 1, order: 2, p: 3 }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3731.667160296904!2d-102.302173!3d21.9273707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ef8d331943d9%3A0xd69b9ef14d061d98!2sConfidental!5e0!3m2!1ses-419!2smx!4v1692105769934!5m2!1ses-419!2smx"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps"
          ></iframe>
        </Box>
      </Box>

      {/* Sección de Suscripción */}
      <Subscription />
    </Box>
  );
};

export default Main;




























