import React, { useState, useContext } from 'react';
import { Box, TextField, Button, Typography, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 
import { AuthContext } from '../context/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const url = isRegistering 
    ? 'https://confidental1.com/api/users/register' 
    : 'https://confidental1.com/api/users/login';

      const payload = {
        email,
        password,
        ...(isRegistering && { name })
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.ok) {
        if (isRegistering) {
          alert('Usuario registrado con éxito');
          setIsRegistering(false);
        } else {
          login(data.token);
        }
      } else {
        alert('Error: ' + data.error);
      }
    } catch (error) {
      alert('Error en la solicitud: ' + error.message);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        background: 'linear-gradient(45deg, #007AFF, #00C6FF, #00F7FF, #0093E9, #0061FF, #001AFF)',
        backgroundSize: '400% 400%',
        animation: 'gradientAnimation 20s ease infinite',
        p: 3,
        '@keyframes gradientAnimation': {
          '0%': {
            backgroundPosition: '0% 50%',
          },
          '50%': {
            backgroundPosition: '100% 50%',
          },
          '100%': {
            backgroundPosition: '0% 50%',
          },
        },
      }}
    >
      {/* Imagen circular, mitad dentro y mitad fuera del Card */}
      <Box
        sx={{
          position: 'absolute',
          top: '0%', // Ajustar posición para que la mitad esté fuera
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          zIndex: 1, // Colocar encima del Card
        }}
      >
        <Box
          sx={{
            width: 250,
            height: 250,
            borderRadius: '50%',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <img
            src="CONFIDENTALLOGO.png"
            alt="Logo"
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      </Box>

      <Card
        sx={{
          maxWidth: 400,
          width: '100%',
          boxShadow: '0 8px 20px rgba(0, 0, 0, 0.6)',
          borderRadius: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          position: 'relative',
          top: '0px', // Empujar hacia abajo para que el logo quede a la mitad
        }}
      >
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', color: '#333' }}>
            {isRegistering ? 'Crear Cuenta' : ''}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            {isRegistering && (
              <TextField
                label="Nombre Completo"
                variant="outlined"
                fullWidth
                margin="normal"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            )}
            <TextField
              label="Usuario"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              label="Contraseña"
              variant="outlined"
              fullWidth
              margin="normal"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                mt: 3,
                p: 1,
                fontSize: '1rem',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: '#5a67d8',
                  transform: 'scale(1.05)',
                }
              }}
            >
              {isRegistering ? 'Registrar' : 'Iniciar Sesión'}
            </Button>
          </Box>
          {/*<Button
            onClick={() => setIsRegistering(!isRegistering)}
            sx={{
              mt: 2,
              textTransform: 'none',
              color: '#667eea',
              '&:hover': {
                backgroundColor: '#e2e8f0',
                borderRadius: '8px'
              }
            }}
            fullWidth
          >
            {isRegistering ? '¿Ya tienes cuenta? Iniciar Sesión' : 'Crear nueva cuenta'}
          </Button>*/}
          <Button
            onClick={() => navigate('/')}
            sx={{
              mt: 2,
              textTransform: 'none',
              color: '#764ba2',
              '&:hover': {
                backgroundColor: '#f0f4f8',
                borderRadius: '8px'
              }
            }}
            fullWidth
          >
            Regresar al Menú
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;

