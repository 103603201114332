import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Subscription from './Subscription';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { keyframes } from '@mui/system';
import Citas from './Citas';

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const RehabilitacionImplantologia = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#e3f2fd', p: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            mb: 3,
          }}
        >
          <Box sx={{ flex: 1, textAlign: 'center', mb: { xs: 3, md: 0 } }}>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                color: '#1976d2',
                fontFamily: 'Roboto, sans-serif',
                animation: `${slideIn} 1s ease-out forwards`,
              }}
            >
              Rehabilitación e Implantología
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: '#1976d2',
                fontFamily: 'Roboto, sans-serif',
                animation: `${fadeIn} 1.5s ease-out forwards`,
                opacity: 0,
              }}
            >
              "Christian Iván Navarro Sánchez"
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#424242',
                fontFamily: 'Arial, sans-serif',
                animation: `${fadeIn} 1.5s ease-out forwards`,
                opacity: 0,
                bgcolor: 'transparent',
                p: 2,
              }}
            >
              "La rehabilitación e implantología oral es un área de la odontología que restaura la función y estética dental mediante el uso de implantes dentales y otros procedimientos reconstructivos. Nuestros tratamientos innovadores están diseñados para ofrecer una solución duradera, devolviendo a los pacientes la confianza en su sonrisa y la funcionalidad completa de su boca."
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay>
              <div>
                <img
                  src="/dentistas.jpg"
                  alt="Rehabilitación e Implantología Imagen 1"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </div>
              <div>
                <img
                  src="/10.jpg"
                  alt="Rehabilitación e Implantología Imagen 2"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </div>
            </Carousel>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, bgcolor: 'white', p: 3 }}>
        <Citas initialSpecialization="Rehabilitación e Implantología" />
      </Box>
      <Subscription />
    </Box>
  );
};

export default RehabilitacionImplantologia;




