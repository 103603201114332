// EditImages.js
import React from 'react';
import { useParams } from 'react-router-dom';

const EditImages = () => {
  const { id } = useParams();

  return (
    <div>
      <h2>Editar Imágenes del Paciente {id}</h2>
      {/* Aquí agregarías el código para editar imágenes */}
    </div>
  );
};

export default EditImages;