import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  Button,  // Importar el componente Button
  Modal, 
  MenuItem, 
  Select, 
  IconButton 
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import SignatureCanvas from 'react-signature-canvas';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

const ViewImages = () => {
  const { patientId } = useParams();
  const [patientData, setPatientData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false); 
  const [textFields, setTextFields] = useState([]); 
  const [fontSize, setFontSize] = useState(16);
  const [fontFamily, setFontFamily] = useState('Arial');
  const [isSigning, setIsSigning] = useState(false); 
  const [signatures, setSignatures] = useState([]); 
  const navigate = useNavigate();

  const textRefs = useRef([]);
  const sigCanvas = useRef({});
  const dragItem = useRef(null);
  const dragStartX = useRef(0);
  const dragStartY = useRef(0);

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://confidental1.com/api/patients/${patientId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const data = await response.json();
          console.error(`Error: ${data.error}`);
          return;
        }

        const data = await response.json();
        setPatientData(data);
      } catch (error) {
        console.error("Error al realizar la solicitud de datos del paciente:", error);
      }
    };

    if (patientId) {
      fetchPatientData();
    }
  }, [patientId]);

  const handleAddTextField = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setTextFields([...textFields, { x, y, value: '', fontSize, fontFamily }]);
  };

  const handleTextFieldInput = (index, event) => {
    const newTextFields = [...textFields];
    newTextFields[index].value = event.currentTarget.innerText;
    setTextFields(newTextFields);

    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(textRefs.current[index]);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleTextFieldBlur = (index) => {
    const newTextFields = [...textFields];
    if (!newTextFields[index].value.trim()) {
      setTextFields(textFields.filter((_, i) => i !== index));
    } else {
      setTextFields(newTextFields);
    }
  };

  const handleTextFieldDrag = (index, e) => {
    const rect = e.target.getBoundingClientRect();
    const newTextFields = [...textFields];
    newTextFields[index].x = e.clientX - rect.width / 2;
    newTextFields[index].y = e.clientY - rect.height / 2;
    setTextFields(newTextFields);
  };

  const handleSignatureDragStart = (index, e) => {
    dragItem.current = index;
    dragStartX.current = e.clientX;
    dragStartY.current = e.clientY;
  };

  const handleSignatureDragOver = (e) => {
    e.preventDefault();
  };

  const handleSignatureDrop = (e) => {
    e.preventDefault();
    const index = dragItem.current;
    const deltaX = e.clientX - dragStartX.current;
    const deltaY = e.clientY - dragStartY.current;
    const newSignatures = [...signatures];
    newSignatures[index].x += deltaX;
    newSignatures[index].y += deltaY;
    setSignatures(newSignatures);
  };

  // Función vacía para evitar el error de la función no definida
  const handleSignatureResize = () => {
    // Lógica para manejar el redimensionamiento de las firmas si es necesario
  };

  const handleSaveChanges = async () => {
    const nonEmptyTextFields = textFields.filter(field => field.value.trim() !== '');
    setTextFields(nonEmptyTextFields);

    if (selectedImage) {
      const pencilButton = document.getElementById('pencil-button');
      if (pencilButton) {
        pencilButton.style.display = 'none';
      }

      const canvas = await html2canvas(document.querySelector("#image-container"));
      const imgData = canvas.toDataURL("image/png");

      if (pencilButton) {
        pencilButton.style.display = 'block';
      }

      const a = document.createElement("a");
      a.href = imgData;
      a.download = "image-with-text.png";
      a.click();
    }

    setIsEditing(false);
    console.log('Cambios guardados:', nonEmptyTextFields);
  };

  const handleDeleteFile = async (fileId) => {
    const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar este archivo?");
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://confidental1.com/api/patients/${patientId}/files/${fileId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const data = await response.json();
        alert(`Error: ${data.error}`);
        return;
      }

      setPatientData(prevData => ({
        ...prevData,
        files: prevData.files.filter(file => file._id !== fileId),
      }));

      alert("Archivo eliminado con éxito.");
    } catch (error) {
      console.error("Error al eliminar el archivo:", error);
      alert(`Error al eliminar el archivo: ${error.message}`);
    }
  };

  const handleDownloadFile = (fileUrl, filename) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename;
    link.click();
  };

  // Función para imprimir el archivo
  const handlePrintFile = (fileUrl) => {
  const printWindow = window.open('', '_blank');
  
  printWindow.document.write(`
    <html>
      <head>
        <style>
          @media print {
            body, html {
              margin: 0;
              padding: 0;
              height: 100%;
              width: 100%;
            }
            img {
              margin: 0.5cm;
              width: calc(100% - 1cm); /* Ajusta el tamaño para compensar el margen */
              height: calc(100vh - 1cm); /* Ajusta la altura para compensar el margen */
              object-fit: contain; /* Ajusta la imagen dentro del contenedor sin recortarla */
            }
          }
        </style>
      </head>
      <body>
        <img src="${fileUrl}" />
      </body>
    </html>
  `);

  printWindow.document.close();

  // Espera a que la ventana se cargue completamente antes de ejecutar la impresión
  printWindow.onload = function() {
    setTimeout(() => {
      printWindow.focus();
      printWindow.print();
    }, 500); // Espera medio segundo antes de ejecutar la impresión
  };
};

  
  
  

  const renderFiles = () => {
    if (!patientData || !patientData.files || patientData.files.length === 0) {
      return <Typography>No hay archivos disponibles</Typography>;
    }

    return patientData.files.map((file, index) => {
      if (file.data && file.data.data) {
        try {
          const blob = new Blob([new Uint8Array(file.data.data)], { type: file.contentType });
          const fileUrl = URL.createObjectURL(blob);

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <img
                    src={fileUrl}
                    alt={`Archivo ${index + 1}`}
                    style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedImage(fileUrl);
                      setIsEditing(false);
                      setTextFields([]);
                      setSignatures([]); 
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteFile(file._id)}
                      sx={{ mr: 1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => handleDownloadFile(fileUrl, file.filename)}
                      sx={{ mr: 1 }}
                    >
                      <DownloadIcon />
                    </IconButton>
                    <IconButton
                      color="inherit"
                      onClick={() => handlePrintFile(fileUrl)}
                      title="Imprimir"
                    >
                      <PrintIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        } catch (error) {
          console.error("Error al renderizar el archivo:", error);
        }
      } else {
        console.error("Los datos del archivo no son válidos.");
      }
      return null;
    });
  };

  const handleAddSignature = () => {
    const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setSignatures([...signatures, { x: 50, y: 50, width: 150, height: 100, dataUrl }]);
    setIsSigning(false); 
    setIsEditing(true); 
  };

  if (!patientData) {
    return <Typography>Cargando datos del paciente...</Typography>;
  }

  return (
    <Box sx={{ p: 3, position: 'relative' }}>
      <Typography variant="h4" gutterBottom>
        Archivos y Observaciones de {patientData.name}
      </Typography>
      <Grid container spacing={1} sx={{ mt: 3 }}>
        {renderFiles()}
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate(`/upload-files/${patientId}`)}
        sx={{ mt: 3 }}
      >
        Subir Nuevos Archivos
      </Button>

      <Modal
        open={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{
          position: 'relative',
          width: '90%',
          height: '90%',
          overflow: 'auto',
          backgroundColor: 'white',
        }}>
          <Box
            id="image-container"
            sx={{ position: 'relative', display: 'inline-block' }}
            onClick={isEditing ? handleAddTextField : null}
            onDragOver={handleSignatureDragOver}
            onDrop={handleSignatureDrop}
          >
            <img
              src={selectedImage}
              alt="Archivo ampliado"
              style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%' }}
            />
            {isEditing && (
              <>
                {textFields.map((field, index) => (
                  <Box
                    key={index}
                    ref={el => textRefs.current[index] = el}
                    contentEditable
                    suppressContentEditableWarning
                    onInput={(e) => handleTextFieldInput(index, e)}
                    onBlur={() => handleTextFieldBlur(index)}
                    style={{
                      position: 'absolute',
                      top: field.y,
                      left: field.x,
                      fontSize: `${field.fontSize}px`,
                      fontFamily: field.fontFamily,
                      backgroundColor: 'transparent',
                      padding: 0,
                      minWidth: '20px',
                      whiteSpace: 'nowrap',
                      border: 'none',
                      outline: 'none',
                      color: 'black',
                      cursor: 'text'
                    }}
                    draggable
                    onDrag={(e) => handleTextFieldDrag(index, e)}
                  >
                    {field.value}
                  </Box>
                ))}
                {signatures.map((signature, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: 'absolute',
                      top: signature.y,
                      left: signature.x,
                      cursor: 'move',
                      width: signature.width,
                      height: signature.height,
                      resize: 'both',
                      overflow: 'auto',
                    }}
                    draggable
                    onDragStart={(e) => handleSignatureDragStart(index, e)}
                    onDragEnd={(e) => handleSignatureDrop(e)}
                    onResize={(e) => handleSignatureResize(index, e)}
                  >
                    <img 
                      src={signature.dataUrl} 
                      alt={`Firma ${index + 1}`} 
                      style={{ width: '100%', height: '100%' }} 
                    />
                  </Box>
                ))}
                <IconButton
                  id="pencil-button"
                  color="secondary"
                  onClick={() => setIsSigning(true)}
                  sx={{ position: 'absolute', top: 10, right: 300, zIndex: 11 }}
                >
                  <EditIcon />
                </IconButton>
              </>
            )}
          </Box>
          <Box sx={{ position: 'absolute', top: 10, left: 10, zIndex: 10 }}>
            {isEditing && (
              <>
                <Select
                  value={fontSize}
                  onChange={(e) => setFontSize(e.target.value)}
                  sx={{ mr: 2 }}
                >
                  {[8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 56, 64].map(size => (
                    <MenuItem key={size} value={size}>
                      {size}px
                    </MenuItem>
                  ))}
                </Select>

                <Select
                  value={fontFamily}
                  onChange={(e) => setFontFamily(e.target.value)}
                  sx={{ mr: 2 }}
                >
                  {['Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana'].map(font => (
                    <MenuItem key={font} value={font}>
                      {font}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsEditing(!isEditing)}
            sx={{ position: 'absolute', top: 10, right: 10 }}
          >
            {isEditing ? 'Ocultar' : 'Editar'}
          </Button>
          {isEditing && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSaveChanges}
              sx={{ position: 'absolute', top: 10, right: 130 }}
            >
              Guardar archivo
            </Button>
          )}
        </Box>
      </Modal>

      <Modal
        open={isSigning}
        onClose={() => setIsSigning(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: '8px' }}>
          <Typography variant="h6" gutterBottom>Firma aquí</Typography>
          <SignatureCanvas
            penColor="black"
            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
            ref={sigCanvas}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button variant="contained" onClick={() => sigCanvas.current.clear()}>Limpiar</Button>
            <Button variant="contained" color="primary" onClick={handleAddSignature}>Guardar Firma</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ViewImages;





























