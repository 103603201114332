// src/components/Footer.js

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: '#060E3C', color: 'white', p: 2, mt: 0, textAlign: 'center' }}>
      <Typography variant="body1" gutterBottom>
        Horario: Lunes a Viernes de 8:00 am a 5:00 pm
      </Typography>
      <Box>
        <IconButton
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
          sx={{ mx: 1 }}
        >
          <FacebookIcon />
        </IconButton>
        <IconButton
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
          sx={{ mx: 1 }}
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          href="https://twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
          sx={{ mx: 1 }}
        >
          <TwitterIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;

