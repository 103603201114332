// DeleteImages.js
import React from 'react';
import { useParams } from 'react-router-dom';

const DeleteImages = () => {
  const { id } = useParams();

  return (
    <div>
      <h2>Eliminar Imágenes del Paciente {id}</h2>
      {/* Aquí agregarías el código para eliminar imágenes */}
    </div>
  );
};

export default DeleteImages;