import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import Subscription from './Subscription';
import Footer from './Footer';

const dentists = [
  { name: 'Dr. Eladio Robledo', specialty: 'Endodoncia' },
  { name: 'Dra. Diana Dueñas', specialty: 'Odontología General' },
  { name: 'Dr. Sergio Garcia', specialty: 'Cirugía Maxilofacial' },
  { name: 'Dra. Vanessa Donato', specialty: 'Odontopediatría' },
  { name: 'Dra. Patricia Moreno', specialty: 'Ortodoncia' },
  { name: 'Dr. Christian Navarro', specialty: 'Rehabilitación e Implantología' }
];

const assistants = [
  { name: 'Asistente 1' },
  { name: 'Asistente 2' },
  { name: 'Asistente 3' }
];

const receptionists = [
  { name: 'Recepcionista 1' },
  { name: 'Recepcionista 2' }
];

const comentarios = [
  { autor: "Lucero Martin", comentario: "El trato del doctor Eladio Robledo es excelente, pone música mientras te realiza la endodoncia y hace todo el procedimiento super cuidadoso de que te sientas bien en todo momento. Si lo necesitan, atiéndanse ahí", rating: 5 },
  { autor: "Mónica Martínez", comentario: "El trato hacia mi hijo que fue el paciente fue excelente, lo trataron muy bonito, hasta música a elegir le dieron mientras hacían su endodoncia, y todo super limpio, los doctores super atentos y amables. 10/10 gracias doctor Eladio Robledo", rating: 5 },
  { autor: "Efrain Rubio", comentario: "Excelente atención con el doctor Sergio, me explicó de principio a fin los cuidados de mi extracción de molares. Mi recuperación fue muy rápida y sin complicaciones. 100% recomendado 👍…", rating: 5 }
];

const handleReviewClick = () => {
  window.open("https://www.google.com/maps/place/Confidental/@21.9273707,-102.302173,18.08z/data=!4m8!3m7!1s0x8429ef8d331943d9:0xd69b9ef14d061d98!8m2!3d21.9274093!4d-102.301363!9m1!1b1!16s%2Fg%2F11vd8wgmch?entry=ttu&review_place_id=ChIJAQB9nDYmd4kRoMfRYczF4bE", "_blank");
};

const SobreNosotros = () => {
  return (
    <Box>
      {/* Sección "Sobre Nosotros" */}
      <Box sx={{ bgcolor: '#060E3C', color: 'white', py: 5, textAlign: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Sobre Nosotros
        </Typography>
        <Typography variant="body1">
          Somos una clínica dental comprometida con la salud y el bienestar de nuestros pacientes.
        </Typography>
      </Box>

      {/* Sección Dentistas */}
      <Box sx={{ py: 5, px: { xs: 2, sm: 3 } }}>
        <Typography variant="h4" component="h2" gutterBottom sx={{ textAlign: 'center', mb: 4 }}>
          Dentistas
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {dentists.map((dentist, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ maxWidth: 345, margin: '0 auto' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image="/dentistas.jpg"
                  alt={dentist.name}
                />
                <CardContent>
                  <Typography variant="h6" component="div">
                    {dentist.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {dentist.specialty}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Sección Asistentes */}
      <Box sx={{ py: 5, px: { xs: 2, sm: 3 }, bgcolor: '#f5f5f5' }}>
        <Typography variant="h4" component="h2" gutterBottom sx={{ textAlign: 'center', mb: 4 }}>
          Asistentes
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {assistants.map((assistant, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ maxWidth: 345, margin: '0 auto' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image="/dentistas.jpg"
                  alt={assistant.name}
                />
                <CardContent>
                  <Typography variant="h6" component="div">
                    {assistant.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Sección Recepción */}
      <Box sx={{ py: 5, px: { xs: 2, sm: 3 } }}>
        <Typography variant="h4" component="h2" gutterBottom sx={{ textAlign: 'center', mb: 4 }}>
          Recepción
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {receptionists.map((receptionist, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ maxWidth: 345, margin: '0 auto' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image="/dentistas.jpg"
                  alt={receptionist.name}
                />
                <CardContent>
                  <Typography variant="h6" component="div">
                    {receptionist.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Sección del Mapa y Comentarios */}
      <Box sx={{ p: 3, textAlign: 'center', mt: 4, display: 'flex', flexDirection: 'column' }}>
        {/* Comentarios */}
        <Box sx={{ flex: 1, order: 1, p: 3 }}>
          {comentarios.map((comentario, index) => (
            <Card key={index} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {comentario.autor}
                </Typography>
                <Rating value={comentario.rating} readOnly />
                <Typography variant="body2" sx={{ color: 'gray' }}>
                  {comentario.comentario}
                </Typography>
              </CardContent>
            </Card>
          ))}
          <Button
            variant="contained"
            sx={{ mt: 2, bgcolor: '#0142A8' }}
            onClick={handleReviewClick}
          >
            Agregar Reseña
          </Button>
        </Box>

        {/* Mapa pegado abajo */}
        <Box sx={{ flex: 1, order: 2, p: 3 }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3731.667160296904!2d-102.302173!3d21.9273707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ef8d331943d9%3A0xd69b9ef14d061d98!2sConfidental!5e0!3m2!1ses-419!2smx!4v1692105769934!5m2!1ses-419!2smx"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps"
          ></iframe>
        </Box>
      </Box>

      <Subscription />
      <Footer />
    </Box>
  );
};

export default SobreNosotros;




