import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, TextField, Accordion, AccordionSummary, AccordionDetails, IconButton, Select, MenuItem, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import SignatureCanvas from 'react-signature-canvas';
import CanvasDraw from 'react-canvas-draw'; // Importamos la librería para dibujar
import BrushIcon from '@mui/icons-material/Brush'; // Importamos el icono del lápiz

const HistoriaClinica = () => {
    const [formTitle, setFormTitle] = useState('');
    const [questions, setQuestions] = useState([]);
    const [savedForms, setSavedForms] = useState([]);
    const [error, setError] = useState('');
    const [isDrawing, setIsDrawing] = useState({}); // Estado para controlar si estamos dibujando en cada pregunta
    const [brushColor, setBrushColor] = useState("#000000"); // Estado para el color del lápiz
    const sigCanvasRef = useRef([]); // Referencia para el componente de firma
    const canvasDrawRef = useRef([]); // Referencia para los canvas de dibujo
    const [imageDimensions, setImageDimensions] = useState({}); // Estado para almacenar las dimensiones de la imagen

    // Función para obtener los formularios guardados
    const fetchForms = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://confidental1.com/api/formularios/user`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                setError('Error al cargar los formularios');
                return;
            }
            const data = await response.json();
            setSavedForms(data || []);
        } catch (error) {
            setError('Error al conectarse con el servidor');
        }
    };

    // Obtener formularios al montar el componente
    useEffect(() => {
        fetchForms();
    }, []);

    // Añadir una nueva pregunta al formulario
    const addQuestion = () => {
        setQuestions([...questions, { text: '', type: 'open', options: [], signature: '', image: null }]);
    };

    // Manejar cambios en las preguntas del formulario
    const handleQuestionChange = (index, field, value) => {
        const newQuestions = [...questions];
        newQuestions[index][field] = value;
        setQuestions(newQuestions);
    };

    // Manejar cambios en las opciones de las preguntas de opción múltiple
    const handleOptionChange = (qIndex, oIndex, value) => {
        const newQuestions = [...questions];
        newQuestions[qIndex].options[oIndex] = value;
        setQuestions(newQuestions);
    };

    // Manejar la subida de imágenes
    const handleImageUpload = (index, event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            handleQuestionChange(index, 'image', reader.result); // Guardar la imagen como Base64
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    // Para obtener las dimensiones de la imagen
    const handleImageLoad = (index, event) => {
        const { width, height } = event.target;
        setImageDimensions(prevDimensions => ({
            ...prevDimensions,
            [index]: { width, height }
        }));
    };

    // Añadir una nueva opción a una pregunta de opción múltiple
    const addOption = (index) => {
        const newQuestions = [...questions];
        newQuestions[index].options.push('');
        setQuestions(newQuestions);
    };

    // Eliminar una pregunta del formulario
    const removeQuestion = (index) => {
        const newQuestions = questions.filter((_, i) => i !== index);
        setQuestions(newQuestions);
    };

    // Capturar firma y actualizar el estado de la pregunta correspondiente
    const captureSignature = (index) => {
        if (sigCanvasRef.current[index]) {
            const signatureDataUrl = sigCanvasRef.current[index].getTrimmedCanvas().toDataURL('image/png');
            handleQuestionChange(index, 'signature', signatureDataUrl); // Guardar la firma en el campo de la pregunta
        }
    };

    // Guardar el formulario
    const saveForm = async () => {
        const token = localStorage.getItem('token');
        const newForm = { title: formTitle || 'Formulario sin título', questions };

        try {
            // Capturar todas las firmas antes de guardar el formulario
            questions.forEach((question, index) => {
                if (question.type === 'signature') {
                    captureSignature(index);
                }
            });

            const response = await fetch('https://confidental1.com/api/formularios', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(newForm),
            });

            if (response.ok) {
                setFormTitle('');
                setQuestions([]);
                setError('');

                // Volver a obtener todos los formularios para refrescar la lista
                await fetchForms();
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Error al guardar el formulario');
            }
        } catch (error) {
            setError('Error al guardar el formulario');
        }
    };

    // Eliminar un formulario guardado
    const deleteForm = async (formId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://confidental1.com/api/formularios/${formId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setSavedForms(savedForms.filter(form => form._id !== formId));
            } else {
                setError('Error al eliminar el formulario');
            }
        } catch (error) {
            setError('Error al eliminar el formulario');
        }
    };

    // Alternar el modo de dibujo para una pregunta en específico
    const toggleDrawing = (index) => {
        setIsDrawing(prevState => ({
            ...prevState,
            [index]: !prevState[index] // Cambiar el estado de dibujo solo para esta pregunta
        }));
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>Historia Clínica</Typography>
            {error && <Typography color="error">{error}</Typography>}

            {/* Sección para crear un formulario */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h5">Crear Formulario</Typography>
                <TextField
                    fullWidth
                    label="Título del Formulario"
                    variant="outlined"
                    value={formTitle}
                    onChange={(e) => setFormTitle(e.target.value)}
                    sx={{ mb: 2 }}
                />
                {questions.map((question, index) => (
                    <Box key={index} sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label={`Pregunta ${index + 1}`}
                            variant="outlined"
                            value={question.text}
                            onChange={(e) => handleQuestionChange(index, 'text', e.target.value)}
                            sx={{ mb: 1 }}
                        />
                        <Select
                            fullWidth
                            value={question.type}
                            onChange={(e) => handleQuestionChange(index, 'type', e.target.value)}
                            sx={{ mb: 1 }}
                        >
                            <MenuItem value="text-only">Solo Texto</MenuItem>
                            <MenuItem value="open">Respuesta Abierta</MenuItem>
                            <MenuItem value="multiple">Opción Múltiple</MenuItem>
                            <MenuItem value="signature">Firma</MenuItem>
                            <MenuItem value="image">Imagen</MenuItem> {/* Nueva opción de Imagen */}
                        </Select>

                        {question.type === 'multiple' && (
                            <Box>
                                {question.options.map((option, oIndex) => (
                                    <TextField
                                        key={oIndex}
                                        fullWidth
                                        label={`Opción ${oIndex + 1}`}
                                        variant="outlined"
                                        value={option}
                                        onChange={(e) => handleOptionChange(index, oIndex, e.target.value)}
                                        sx={{ mb: 1 }}
                                    />
                                ))}
                                <Button onClick={() => addOption(index)} variant="contained" sx={{ mb: 2 }}>
                                    Agregar Opción
                                </Button>
                            </Box>
                        )}

                        {question.type === 'signature' && (
                            <Box>
                                <Typography variant="body2">Espacio para la firma:</Typography>
                                <SignatureCanvas
                                    ref={(el) => sigCanvasRef.current[index] = el}
                                    penColor="black"
                                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                />
                                <Button onClick={() => sigCanvasRef.current[index].clear()} variant="outlined" sx={{ mt: 2 }}>
                                    Limpiar Firma
                                </Button>
                            </Box>
                        )}

                        {question.type === 'image' && (
                            <Box>
                                <Typography variant="body2">Sube una imagen:</Typography>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageUpload(index, e)}
                                />
                                {question.image && (
                                    <Box sx={{ mt: 2, position: 'relative' }}>
                                        <img
                                            src={question.image}
                                            alt={`Pregunta ${index + 1}`}
                                            style={{
                                                width: '100%',
                                                pointerEvents: isDrawing[index] ? 'none' : 'auto',  // Desactiva los eventos sobre la imagen cuando se dibuja
                                            }}
                                            onLoad={(e) => handleImageLoad(index, e)} // Obtener dimensiones de la imagen
                                        />
                                        {/* Botón para activar el lápiz */}
                                        <IconButton
                                            onClick={() => toggleDrawing(index)}
                                            sx={{ position: 'absolute', top: 10, right: 10, zIndex: 3, }}
                                        >
                                            <BrushIcon />
                                        </IconButton>
                                        {/* Canvas para dibujar sobre la imagen */}
                                        {isDrawing[index] && imageDimensions[index] && (
                                            <CanvasDraw
                                                ref={el => canvasDrawRef.current[index] = el}
                                                brushColor={brushColor}
                                                canvasWidth={imageDimensions[index].width} // Ancho dinámico
                                                canvasHeight={imageDimensions[index].height} // Altura dinámica
                                                hideGrid={true}
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    background: 'transparent',

                                                    zIndex: 1,
                                                }}
                                            />
                                        )}
                                        {/* Selector de color del lápiz */}
                                        {isDrawing[index] && (
                                            <Box sx={{ mt: 2 }}>
                                                <Typography>Selecciona el color del lápiz:</Typography>
                                                <input
                                                    type="color"
                                                    value={brushColor}
                                                    onChange={(e) => setBrushColor(e.target.value)}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        )}

                        <IconButton onClick={() => removeQuestion(index)} sx={{ ml: 1 }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                ))}

                {/* Botones alineados */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" color="primary" onClick={addQuestion} sx={{ mb: 2 }}>
                        Agregar Pregunta
                    </Button>
                    <Button variant="contained" color="success" onClick={saveForm}>
                        Guardar Formulario
                    </Button>
                </Box>
            </Box>

            {/* Sección para mostrar los formularios guardados */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h5">Formularios Guardados</Typography>
                {savedForms.length === 0 ? (
                    <Typography>No hay formularios guardados.</Typography>
                ) : (
                    savedForms.map((form, index) => (
                        <Accordion key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                    <Typography>{form.title || 'Formulario sin título'}</Typography>
                                    <IconButton onClick={() => deleteForm(form._id)} sx={{ ml: 1 }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                {form.questions && form.questions.map((question, qIndex) => (
                                    <Box key={qIndex} sx={{ mb: 2 }}>
                                        <Typography variant="body1">{question.text}</Typography>

                                        {/* Verificar si la pregunta no es de tipo 'text-only' para mostrar el cuadro de respuesta */}
                                        {question.type !== 'text-only' && (
                                            <>
                                                {question.type === 'multiple' ? (
                                                    <RadioGroup name={`question-${qIndex}`}>
                                                        {question.options.map((option, oIndex) => (
                                                            <FormControlLabel
                                                                key={oIndex}
                                                                value={option}
                                                                control={<Radio />}
                                                                label={option}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                ) : question.type === 'signature' ? (
                                                    <SignatureCanvas
                                                        penColor="black"
                                                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                                        ref={sigCanvasRef}
                                                    />
                                                ) : question.type === 'image' ? (
                                                    <Box sx={{ position: 'relative' }}>
                                                        <img
                                                            src={question.image}
                                                            alt={`Pregunta ${qIndex + 1}`}
                                                            style={{ width: '100%' }}
                                                            onLoad={(e) => handleImageLoad(qIndex, e)} // Obtener dimensiones
                                                        />
                                                        {/* Canvas para dibujar sobre la imagen */}
                                                        {imageDimensions[qIndex] && (
                                                            <CanvasDraw
                                                                ref={el => canvasDrawRef.current[qIndex] = el}
                                                                brushColor={brushColor}
                                                                canvasWidth={imageDimensions[qIndex].width}
                                                                canvasHeight={imageDimensions[qIndex].height}
                                                                hideGrid={true}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                    background: 'transparent',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    zIndex: 1
                                                                }}
                                                            />
                                                        )}
                                                        {/* Botón para activar el lápiz */}
                                                        <IconButton
                                                            onClick={() => toggleDrawing(qIndex)}
                                                            sx={{ position: 'absolute', top: 10, right: 10 }}
                                                        >
                                                            <BrushIcon />
                                                        </IconButton>
                                                        {/* Selector de color del lápiz */}
                                                        {isDrawing[qIndex] && (
                                                            <Box sx={{ mt: 2 }}>
                                                                <Typography>Selecciona el color del lápiz:</Typography>
                                                                <input
                                                                    type="color"
                                                                    value={brushColor}
                                                                    onChange={(e) => setBrushColor(e.target.value)}
                                                                />
                                                            </Box>
                                                        )}
                                                    </Box>
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        label={`Respuesta ${qIndex + 1}`}
                                                        variant="outlined"
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Box>
                                ))}

                            </AccordionDetails>
                        </Accordion>
                    ))
                )}
            </Box>
        </Box>
    );
};

export default HistoriaClinica;