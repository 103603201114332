import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    MenuItem,
    Grid,
    Tabs,
    Tab,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';

const Imprimir = () => {
    const { patientId } = useParams();
    const [savedForms, setSavedForms] = useState([]);
    const [savedResponses, setSavedResponses] = useState([]);
    const [selectedResponses, setSelectedResponses] = useState([]);
    const [expandedFormId, setExpandedFormId] = useState(null);
    const [error, setError] = useState('');

    // Estado para el diálogo de configuración de impresión
    const [openConfigDialog, setOpenConfigDialog] = useState(false);
    const [fontSize, setFontSize] = useState('12');
    const [fontFamily, setFontFamily] = useState('Arial');
    const [imageSize, setImageSize] = useState('100');
    const [columns, setColumns] = useState('2');
    const [textColor, setTextColor] = useState('#000000');
    const [pageFormats] = useState({}); // Formatos por respuesta

    // Nuevo estado para almacenar opciones de formato por respuesta
    const [formatOptionsPerResponse, setFormatOptionsPerResponse] = useState({});

    // Estado para las pestañas
    const [tabValue, setTabValue] = useState(0);

    // Opciones para las configuraciones
    const fontOptions = ['Arial', 'Times New Roman', 'Courier New', 'Verdana'];
    const fontSizeOptions = [
        '6',
        '8',
        '10',
        '12',
        '14',
        '16',
        '18',
        '20',
        '22',
        '24',
        '28',
        '32',
        '36',
        '40',
        '48',
    ];
    const imageSizeOptions = ['7', '15', '25', '50', '75', '100', '125', '150'];
    const columnOptions = ['1', '2', '3', '4', '5'];

    // Función para abrir el diálogo
    const handleOpenConfigDialog = () => {
        setOpenConfigDialog(true);
    };

    // Función para cerrar el diálogo
    const handleCloseConfigDialog = () => {
        setOpenConfigDialog(false);
    };

    // Función para imprimir con la configuración seleccionada
    const handlePrintWithConfig = () => {
        handleCloseConfigDialog();
        handlePrintSelected();
    };

    // Fetch de formularios y respuestas guardadas
    const fetchFormsAndResponses = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('No se encontró el token. Por favor, inicia sesión.');
            return;
        }

        try {
            const formsResponse = await fetch(
                `https://confidental1.com/api/formularios/patient/${patientId}/all`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!formsResponse.ok) {
                setError('Error al cargar los formularios');
                return;
            }

            const formsData = await formsResponse.json();
            setSavedForms(formsData);

            const responsesResponse = await fetch(
                `https://confidental1.com/api/formularios/patient/${patientId}/answered`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!responsesResponse.ok) {
                setError('Error al cargar las respuestas guardadas');
                return;
            }

            const responsesData = await responsesResponse.json();
            setSavedResponses(responsesData);
        } catch (error) {
            setError('Error al conectarse con el servidor');
        }
    }, [patientId]);

    useEffect(() => {
        fetchFormsAndResponses();
    }, [fetchFormsAndResponses]);

    // Manejar la selección de formulario
    const handleSelectForm = (formId) => {
        setExpandedFormId(formId === expandedFormId ? null : formId);
    };

    // Manejar selección múltiple de respuestas para imprimir
    const handleToggleResponseSelection = (responseId) => {
        setSelectedResponses((prevSelected) =>
            prevSelected.includes(responseId)
                ? prevSelected.filter((id) => id !== responseId)
                : [...prevSelected, responseId]
        );
    };

    // Nueva función para manejar cambios en las opciones de formato por respuesta
    const handleResponseFormatChange = (responseId, key, value) => {
        setFormatOptionsPerResponse((prevFormats) => ({
            ...prevFormats,
            [responseId]: {
                ...prevFormats[responseId],
                [key]: value,
            },
        }));
    };

    // Función para aplicar la configuración global a todos los formularios
    const applyGlobalSettingsToAll = () => {
        const newFormatOptions = {};
        selectedResponses.forEach((responseId) => {
            newFormatOptions[responseId] = {
                fontSize,
                fontFamily,
                imageSize,
                columns,
                textColor,
            };
        });
        setFormatOptionsPerResponse(newFormatOptions);
    };

    // Función para imprimir los formularios seleccionados con diferentes formatos por página
    const handlePrintSelected = () => {
        const newWindow = window.open('', '', 'width=800,height=600');

        let perResponseCSS = '';

        const sanitizeId = (id) => id.replace(/[^a-zA-Z0-9-_]/g, '');

        selectedResponses.forEach((responseId) => {
            const sanitizedId = sanitizeId(responseId);
            const formatOptions = formatOptionsPerResponse[responseId] || {};
            const {
                fontSize: resFontSize = fontSize,
                fontFamily: resFontFamily = fontFamily,
                columns: resColumns = columns,
                textColor: resTextColor = textColor,
                imageSize: resImageSize = imageSize,
            } = formatOptions;

            perResponseCSS += `
                .response-${sanitizedId} {
                    font-family: ${resFontFamily};
                    font-size: ${resFontSize}px;
                    color: ${resTextColor};
                }
                .response-${sanitizedId} .question-answer {
                    flex: 1 1 calc(100% / ${resColumns} - 10px);
                }
                .response-${sanitizedId} .question-answer.full-width {
                    flex: 1 1 100%;
                }
                .response-${sanitizedId} img {
                    width: ${resImageSize}%;
                    height: auto;
                }
            `;
        });

        const printCSS = `
            @media print {
                body {
                    padding: 20px;
                    background-color: #fff;
                }
                .imprimir-contenido {
                    margin-top: 120px;
                }
                button, .form-control-label {
                    display: none;
                }
                img.full-page-image {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    opacity: 0.2;
                }
                .logo-encabezado {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    z-index: 2;
                }
                .question-answer-container {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 10px;
                }
                .question-answer.full-width {
                    flex: 1 1 100%;
                    margin-bottom: 10px;
                }
                .question-answer {
                    flex: 1 1 calc(100% / ${columns} - 10px);
                    box-sizing: border-box;
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
                .question-answer p {
                    margin: 0;
                    padding: 0;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                }
                .page-break {
                    page-break-before: always;
                }
                .first-page {
                    page-break-before: avoid;
                }
                ${perResponseCSS}
            }
        `;

        newWindow.document.write('<html><head><title>Imprimir Respuesta</title>');
        newWindow.document.write(`<style>${printCSS}</style>`);
        newWindow.document.write('</head><body>');

        // Insertar imagen de fondo y encabezado
        newWindow.document.write(`
            <img src="/Fondoimprimir.png" alt="Fondo" class="full-page-image" />
            <div class="logo-encabezado" style="text-align: center; margin-bottom: 20px;">
                <img src="/Logoimpresion.png" alt="Encabezado Logo" style="width: 500px;" />
            </div>
        `);

        selectedResponses.forEach((responseId, index) => {
            const sanitizedId = sanitizeId(responseId);
            const printContent = document.getElementById(`response-${responseId}`);
            if (printContent) {
                const formatClass = pageFormats[responseId] === 'Formato 1' ? 'page-one' : 'page-two';
                // Aplicar el formato seleccionado para cada página
                newWindow.document.write(`
                    <div class="imprimir-contenido ${formatClass} response-${sanitizedId} ${
                    index === 0 ? 'first-page' : 'page-break'
                }">
                `);
                newWindow.document.write(printContent.innerHTML);
                newWindow.document.write('</div>');
            }
        });

        newWindow.document.write('</body></html>');
        newWindow.document.close();
        newWindow.focus();

        newWindow.onload = () => {
            newWindow.print();
        };
    };

    // Función para imprimir solo la imagen seleccionada
    const handlePrintImage = (imageSrc, imgSize) => {
        const newWindow = window.open('', '', 'width=800,height=600');
        newWindow.document.write('<html><head><title>Imprimir Imagen</title></head><body>');
        newWindow.document.write(`<img src="${imageSrc}" style="width: ${imgSize}%; height: auto;" />`);
        newWindow.document.write('</body></html>');
        newWindow.document.close();
        newWindow.focus();
        newWindow.onload = () => {
            newWindow.print();
        };
    };

    // Renderizar respuesta, incluyendo imágenes y manejo de preguntas tipo "text-only"
    const renderAnswer = (answer, questionType, responseId) => {
        const isImage = typeof answer === 'string' && answer.startsWith('data:image/');

        // Obtener opciones de formato para esta respuesta
        const formatOptions = formatOptionsPerResponse[responseId] || {};
        const resColumns = formatOptions.columns || columns;
        const imgSize = formatOptions.imageSize || imageSize;

        // Si es imagen
        if (isImage) {
            return (
                <div>
                    <img src={answer} alt="Imagen respuesta" style={{ width: `${imgSize}%`, maxWidth: '100%' }} />
                    <Button variant="outlined" onClick={() => handlePrintImage(answer, imgSize)} sx={{ mt: 1 }}>
                        Imprimir Imagen
                    </Button>
                </div>
            );
        }

        // Si es texto solamente, ocupará una fila completa (100% de ancho)
        if (questionType === 'text-only') {
            return (
                <Box
                    className="question-answer full-width"
                    sx={{
                        flex: '1 1 100%',
                        marginBottom: '10px',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    <Typography variant="body1">{answer}</Typography>
                </Box>
            );
        }

        // Para otras respuestas, se distribuyen en columnas
        return (
            <Box
                className="question-answer"
                sx={{
                    flex: `1 1 calc(100% / ${resColumns} - 10px)`,
                    marginBottom: '10px',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                }}
            >
                <Typography variant="body1">{answer}</Typography>
            </Box>
        );
    };

    // Manejar el cambio de pestañas
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // Función para seleccionar todas las respuestas de un formulario
    const handleSelectAllResponses = (formId) => {
        const responsesForForm = savedResponses
            .filter((response) => response.formId === formId)
            .map((_, index) => `${formId}-${index}`);
        setSelectedResponses((prevSelected) => [...new Set([...prevSelected, ...responsesForForm])]);
    };

    // Obtener los títulos de los formularios seleccionados (No será necesario en este caso)
    // const selectedFormTitles = selectedResponses.map((responseId) => {
    //     const [formId] = responseId.split('-');
    //     const form = savedForms.find((f) => f._id === formId);
    //     return form ? form.title || 'Formulario sin título' : 'Formulario sin título';
    // });

    return (
        <Box sx={{ p: 3 }} className="imprimir-contenido">
            <Typography variant="h4" gutterBottom>
                Vista de Imprimir Formularios
            </Typography>

            {error && <Typography color="error">{error}</Typography>}

            <Box sx={{ mb: 4 }}>
                <Typography variant="h5">Formularios Guardados</Typography>
                {savedForms.length === 0 ? (
                    <Typography>No hay formularios guardados.</Typography>
                ) : (
                    savedForms.map((form) => {
                        const responsesForForm = savedResponses.filter(
                            (response) => response.formId === form._id
                        );

                        return (
                            <Accordion
                                key={form._id}
                                expanded={expandedFormId === form._id}
                                onChange={() => handleSelectForm(form._id)}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">
                                        {form.title || 'Formulario sin título'}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleSelectAllResponses(form._id)}
                                        sx={{ mb: 2 }}
                                    >
                                        Seleccionar todas las respuestas de este formulario
                                    </Button>
                                    {responsesForForm.length > 0 ? (
                                        responsesForForm.map((response, responseIndex) => {
                                            const responseId = `${form._id}-${responseIndex}`;
                                            return (
                                                <Box
                                                    key={responseIndex}
                                                    sx={{ mb: 4 }}
                                                    id={`response-${responseId}`}
                                                >
                                                    <Typography variant="h6">
                                                        Respuesta {responseIndex + 1}
                                                    </Typography>

                                                    <div className="question-answer-container">
                                                        {Array.isArray(response.answers) ? (
                                                            response.answers.map((answer, index) => {
                                                                const questionType =
                                                                    form.questions[index]?.type || 'open';
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className={`question-answer ${
                                                                            questionType === 'text-only'
                                                                                ? 'full-width'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        {form.questions &&
                                                                            form.questions[index] && (
                                                                                <Typography variant="body1">
                                                                                    <strong>
                                                                                        {form.questions[index].text}
                                                                                    </strong>
                                                                                </Typography>
                                                                            )}
                                                                        {renderAnswer(answer, questionType, responseId)}
                                                                    </div>
                                                                );
                                                            })
                                                        ) : (
                                                            <Typography>No hay respuestas disponibles.</Typography>
                                                        )}
                                                    </div>

                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedResponses.includes(responseId)}
                                                                onChange={() =>
                                                                    handleToggleResponseSelection(responseId)
                                                                }
                                                            />
                                                        }
                                                        label="Seleccionar para Imprimir"
                                                        className="form-control-label"
                                                    />
                                                </Box>
                                            );
                                        })
                                    ) : (
                                        <Typography>
                                            No hay respuestas guardadas para este formulario.
                                        </Typography>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })
                )}
            </Box>

            {/* Botón para abrir el diálogo de configuración */}
            <Button
                variant="outlined"
                onClick={handleOpenConfigDialog}
                disabled={selectedResponses.length === 0}
            >
                Imprimir Formularios Seleccionados
            </Button>

            {/* Diálogo de configuración de impresión */}
            <Dialog open={openConfigDialog} onClose={handleCloseConfigDialog} maxWidth="md" fullWidth>
                <DialogTitle>Configuración de impresión</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Elige las opciones para personalizar tu impresión.
                    </DialogContentText>
                    <Typography variant="body2">
                        Has seleccionado {selectedResponses.length} formularios para imprimir.
                    </Typography>

                    {/* Pestañas para Configuración Global y por Respuesta */}
                    <Tabs value={tabValue} onChange={handleTabChange} sx={{ mt: 2 }}>
                        <Tab label="Configuración Global" />
                        {selectedResponses.map((_, index) => (
                            <Tab key={index} label={index + 1} />
                        ))}
                    </Tabs>

                    {tabValue === 0 && (
                        <Box sx={{ mt: 2 }}>
                            <Grid container spacing={2}>
                                {/* Tamaño de letra */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        select
                                        label="Tamaño de letra"
                                        value={fontSize}
                                        onChange={(e) => setFontSize(e.target.value)}
                                        fullWidth
                                        margin="normal"
                                    >
                                        {fontSizeOptions.map((size) => (
                                            <MenuItem key={size} value={size}>
                                                {size}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {/* Tipo de letra */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        select
                                        label="Tipo de letra"
                                        value={fontFamily}
                                        onChange={(e) => setFontFamily(e.target.value)}
                                        fullWidth
                                        margin="normal"
                                    >
                                        {fontOptions.map((font) => (
                                            <MenuItem key={font} value={font}>
                                                {font}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {/* Tamaño de imagen */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        select
                                        label="Tamaño de imagen (%)"
                                        value={imageSize}
                                        onChange={(e) => setImageSize(e.target.value)}
                                        fullWidth
                                        margin="normal"
                                    >
                                        {imageSizeOptions.map((size) => (
                                            <MenuItem key={size} value={size}>
                                                {size}%
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {/* Número de columnas */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        select
                                        label="Número de columnas"
                                        value={columns}
                                        onChange={(e) => setColumns(e.target.value)}
                                        fullWidth
                                        margin="normal"
                                    >
                                        {columnOptions.map((col) => (
                                            <MenuItem key={col} value={col}>
                                                {col} Columnas
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {/* Color de texto */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        label="Color del texto"
                                        type="color"
                                        value={textColor}
                                        onChange={(e) => setTextColor(e.target.value)}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                variant="contained"
                                onClick={applyGlobalSettingsToAll}
                                sx={{ mt: 2 }}
                            >
                                Aplicar configuración global a todos los formularios
                            </Button>
                        </Box>
                    )}

                    {tabValue > 0 && (
                        <Box sx={{ mt: 2 }}>
                            {/* Obtener el responseId correspondiente a la pestaña actual */}
                            {(() => {
                                const responseId = selectedResponses[tabValue - 1]; // Restamos 1 porque la primera pestaña es la global
                                return (
                                    <Box key={responseId} sx={{ mt: 1 }}>
                                        <Typography variant="subtitle1">
                                            Configuración para Formulario {tabValue}:
                                        </Typography>
                                        <Grid container spacing={2}>
                                            {/* Tamaño de letra */}
                                            <Grid item xs={12} sm={6} md={4}>
                                                <TextField
                                                    select
                                                    label="Tamaño de letra"
                                                    value={
                                                        formatOptionsPerResponse[responseId]?.fontSize ||
                                                        fontSize
                                                    }
                                                    onChange={(e) =>
                                                        handleResponseFormatChange(
                                                            responseId,
                                                            'fontSize',
                                                            e.target.value
                                                        )
                                                    }
                                                    fullWidth
                                                    margin="normal"
                                                >
                                                    {fontSizeOptions.map((size) => (
                                                        <MenuItem key={size} value={size}>
                                                            {size}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            {/* Tipo de letra */}
                                            <Grid item xs={12} sm={6} md={4}>
                                                <TextField
                                                    select
                                                    label="Tipo de letra"
                                                    value={
                                                        formatOptionsPerResponse[responseId]?.fontFamily ||
                                                        fontFamily
                                                    }
                                                    onChange={(e) =>
                                                        handleResponseFormatChange(
                                                            responseId,
                                                            'fontFamily',
                                                            e.target.value
                                                        )
                                                    }
                                                    fullWidth
                                                    margin="normal"
                                                >
                                                    {fontOptions.map((font) => (
                                                        <MenuItem key={font} value={font}>
                                                            {font}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            {/* Tamaño de imagen */}
                                            <Grid item xs={12} sm={6} md={4}>
                                                <TextField
                                                    select
                                                    label="Tamaño de imagen (%)"
                                                    value={
                                                        formatOptionsPerResponse[responseId]?.imageSize ||
                                                        imageSize
                                                    }
                                                    onChange={(e) =>
                                                        handleResponseFormatChange(
                                                            responseId,
                                                            'imageSize',
                                                            e.target.value
                                                        )
                                                    }
                                                    fullWidth
                                                    margin="normal"
                                                >
                                                    {imageSizeOptions.map((size) => (
                                                        <MenuItem key={size} value={size}>
                                                            {size}%
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            {/* Número de columnas */}
                                            <Grid item xs={12} sm={6} md={4}>
                                                <TextField
                                                    select
                                                    label="Número de columnas"
                                                    value={
                                                        formatOptionsPerResponse[responseId]?.columns ||
                                                        columns
                                                    }
                                                    onChange={(e) =>
                                                        handleResponseFormatChange(
                                                            responseId,
                                                            'columns',
                                                            e.target.value
                                                        )
                                                    }
                                                    fullWidth
                                                    margin="normal"
                                                >
                                                    {columnOptions.map((col) => (
                                                        <MenuItem key={col} value={col}>
                                                            {col} Columnas
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            {/* Color de texto */}
                                            <Grid item xs={12} sm={6} md={4}>
                                                <TextField
                                                    label="Color del texto"
                                                    type="color"
                                                    value={
                                                        formatOptionsPerResponse[responseId]?.textColor ||
                                                        textColor
                                                    }
                                                    onChange={(e) =>
                                                        handleResponseFormatChange(
                                                            responseId,
                                                            'textColor',
                                                            e.target.value
                                                        )
                                                    }
                                                    fullWidth
                                                    margin="normal"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                            
                                        </Grid>
                                    </Box>
                                );
                            })()}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfigDialog}>Cancelar</Button>
                    <Button onClick={handlePrintWithConfig} variant="contained">
                        Imprimir
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Imprimir;




