import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './components/Main';
import Endodoncia from './components/Endodoncia';
import CirugiaMaxilofacial from './components/CirugiaMaxilofacial';
import Odontopediatria from './components/Odontopediatria';
import Ortodoncia from './components/Ortodoncia';
import RehabilitacionImplantologia from './components/RehabilitacionImplantologia';
import OdontologiaGeneral from './components/OdontologiaGeneral';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Citas from './components/Citas';
import SobreNosotros from './components/SobreNosotros';
import Noticias from './components/Noticias';
import NoticiaDetalle from './components/NoticiaDetalle';
import Login from './components/Login';
import CreateUser from './components/CreateUser'; 
import NewPatient from './components/NewPatient';
import Profile from './components/Profile';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import UploadFiles from './components/UploadFiles';
import ViewImages from './components/ViewImages';
import EditImages from './components/EditImages';
import DeleteImages from './components/DeleteImages';
import EditPatient from './components/EditPatient';
import FloatingWhatsAppButton from './components/FloatingWhatsAppButton';
import HistoriaClinica from './components/HistoriaClinica';
import HistoriaClinicaPersonal from './components/HistoriaClinicaPersonal';
import Imprimir from './components/Imprimir';

const theme = createTheme();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <Header />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/especialidades/endodoncia" element={<Endodoncia />} />
            <Route path="/especialidades/cirugía maxilofacial" element={<CirugiaMaxilofacial />} />
            <Route path="/especialidades/odontopediatria" element={<Odontopediatria />} />
            <Route path="/especialidades/ortodoncia" element={<Ortodoncia />} />
            <Route path="/especialidades/rehabilitación e implantologia" element={<RehabilitacionImplantologia />} />
            <Route path="/especialidades/odontologia general" element={<OdontologiaGeneral />} />
            <Route path="/citas" element={<Citas />} />
            <Route path="/sobre-nosotros" element={<SobreNosotros />} />
            <Route path="/noticias" element={<Noticias />} />
            <Route path="/noticias/:id" element={<NoticiaDetalle />} />
            <Route path="/login" element={<Login />} />


            {/* Ruta protegida */}
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/create-user" element={<ProtectedRoute><CreateUser /></ProtectedRoute>} />
            <Route path="/new-patient" element={<ProtectedRoute><NewPatient /></ProtectedRoute>} />
            <Route path="/view-images/:patientId" element={<ProtectedRoute><ViewImages /></ProtectedRoute>} />
            <Route path="/upload-files/:patientId" element={<ProtectedRoute><UploadFiles /></ProtectedRoute>} />
            <Route path="/edit-images/:id" element={<ProtectedRoute><EditImages /></ProtectedRoute>} />
            <Route path="/delete-images/:id" element={<ProtectedRoute><DeleteImages /></ProtectedRoute>} />
            <Route path="/edit-patient/:patientId" element={<ProtectedRoute><EditPatient /></ProtectedRoute>} />
            <Route path="/historia-clinica-personal/:patientId" element={<ProtectedRoute><HistoriaClinicaPersonal /></ProtectedRoute>} />
            <Route path="/imprimir/:patientId" element={<ProtectedRoute><Imprimir /></ProtectedRoute>} />
            {/* Nueva ruta para Historia Clínica */}
            <Route path="/historia-clinica" element={<ProtectedRoute><HistoriaClinica /></ProtectedRoute>} />
          </Routes>
          <Footer />
          {/* Agrega el botón flotante de WhatsApp */}
          <FloatingWhatsAppButton />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;










