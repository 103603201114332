import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';

const specializations = [
  'Cirugía Maxilofacial',
  'Odontología General',
  'Endodoncia',
  'Odontopediatría',
  'Ortodoncia',
  'Rehabilitación e Implantología'
];

const doctors = {
  'Cirugía Maxilofacial': { name: 'Doctor Sergio Garcia', article: 'el' },
  'Odontología General': { name: 'Doctora Diana Dueñas', article: 'la' },
  'Endodoncia': { name: 'Doctor Eladio Robledo', article: 'el' },
  'Odontopediatría': { name: 'Doctora Vanessa Donato', article: 'la' },
  'Ortodoncia': { name: 'Doctora Patricia Moreno', article: 'la' },
  'Rehabilitación e Implantología': { name: 'Doctor Christian Navarro', article: 'el' }
};

const availableTimes = [
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '1:00 PM',
  '2:00 PM',
  '4:00 PM',
  '5:00 PM',
  '6:00 PM',
  '7:00 PM',
  '8:00 PM'
];

const Citas = ({ initialSpecialization = 'Cirugía Maxilofacial' }) => {
  const [selectedSpecialization, setSelectedSpecialization] = useState(0);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDay, setSelectedDay] = useState('');

  // Efecto para establecer la especialización inicial
  useEffect(() => {
    const initialIndex = specializations.indexOf(initialSpecialization);
    if (initialIndex !== -1) {
      setSelectedSpecialization(initialIndex);
    }
  }, [initialSpecialization]);

  const handlePrevClick = () => {
    setSelectedSpecialization((prev) =>
      (prev - 1 + specializations.length) % specializations.length
    );
  };

  const handleNextClick = () => {
    setSelectedSpecialization((prev) =>
      (prev + 1) % specializations.length
    );
  };

  const handleTimeClick = (time, day) => {
    setSelectedTime(time);
    setSelectedDay(day);
  };

  const generateWhatsAppLink = () => {
    const specialization = specializations[selectedSpecialization];
    const { name, article } = doctors[specialization];
    const message = `Quiero una cita a las ${selectedTime} el ${selectedDay} con ${article} ${name}, especializado en ${specialization}.`;
    const encodedMessage = encodeURIComponent(message);
    return `https://wa.me/524493464846?text=${encodedMessage}`; // Enviar al número específico
  };

  return (
    <Box sx={{ p: 3, textAlign: 'center', maxWidth: '800px', margin: '0 auto' }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 3 }}>
        Agenda Tu Cita
      </Typography>

      <Box
        sx={{
          position: 'relative',
          height: 300,
          overflow: 'hidden',
          mb: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={handlePrevClick}
          sx={{ position: 'absolute', left: 10, zIndex: 3 }}
        >
          <ArrowForwardIosIcon sx={{ transform: 'rotate(180deg)' }} />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          {specializations.map((specialization, index) => {
            const totalCards = specializations.length;
            const offset = (index - selectedSpecialization + totalCards) % totalCards;

            // Adjusted offset for infinite loop effect
            const infiniteOffset = offset > totalCards / 2 ? offset - totalCards : offset;
            const isCenter = infiniteOffset === 0;
            const isVisible = Math.abs(infiniteOffset) <= 1;

            return (
              <Box
                key={specialization}
                sx={{
                  width: 300,
                  height: 200,
                  transform: `translateX(${infiniteOffset * 170}px) scale(${
                    isCenter ? 1.1 : 0.7
                  })`,
                  transition: 'all 1s ease',
                  zIndex: isCenter ? 2 : 1,
                  cursor: 'pointer',
                  position: 'absolute',
                  left: `calc(50% - 150px)`,
                  opacity: isVisible ? 1 : 0,
                  visibility: isVisible ? 'visible' : 'hidden',
                }}
                onClick={() => setSelectedSpecialization(index)}
              >
                <img
                  src={`/C${index + 1}.png`}
                  alt={specialization}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '8px',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            );
          })}
        </Box>

        <IconButton
          onClick={handleNextClick}
          sx={{ position: 'absolute', right: 10, zIndex: 3 }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

      <Collapse in={Boolean(selectedSpecialization || selectedSpecialization === 0)}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
            Horarios Disponibles para {specializations[selectedSpecialization]}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'].map((day) => (
                    <TableCell key={day} align="center">
                      {day}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {availableTimes.map((time) => (
                  <TableRow key={time}>
                    {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'].map((day) => (
                      <TableCell
                        key={day}
                        align="center"
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: 'black',
                            color: 'white',
                          },
                          backgroundColor: selectedTime === time && selectedDay === day ? 'black' : 'inherit',
                          color: selectedTime === time && selectedDay === day ? 'white' : 'inherit',
                          transition: 'background-color 0.3s ease, color 0.3s ease',
                        }}
                        onClick={() => handleTimeClick(time, day)}
                      >
                        {time}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Collapse>

      <Button
        variant="contained"
        color="primary"
        href={generateWhatsAppLink()}
        target="_blank"
        disabled={!selectedTime || !selectedDay}
        sx={{ mt: 2 }}
      >
        Reserva Ahora
      </Button>
    </Box>
  );
};

export default Citas;


























