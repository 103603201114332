import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const NoticiaDetalle = () => {
  const { id } = useParams(); // Obtener el ID de la noticia desde la URL
  const [news, setNews] = useState(null);

  useEffect(() => {
    console.log('ID recibido:', id); // Verifica que el ID se está obteniendo correctamente
    const fetchNews = async () => {
      try {
        const response = await fetch(`https://confidental1.com/api/news/${id}`);
        const data = await response.json();
        setNews(data);
      } catch (error) {
        console.error('Error al obtener la noticia:', error);
      }
    };
  
    fetchNews();
  }, [id]);
  

  if (!news) {
    return <Typography>Noticia no encontrada</Typography>;
  }

  return (
    <Box sx={{ p: 3, textAlign: 'center' }}>
      <Card sx={{ maxWidth: 800, margin: '0 auto' }}>
        <CardMedia
          component="img"
          height="400"
          image={`https://confidental1.com${news.image}`} // Asegúrate de que 'news.image' contiene la ruta correcta
          alt={news.title}
        />
        <CardContent>
          <Typography variant="h4" component="div" gutterBottom>
            {news.title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {news.description}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default NoticiaDetalle;

