import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

const EditPatient = () => {
  const { patientId } = useParams(); // Obtener el ID del paciente desde la URL
  const [patientData, setPatientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        const response = await fetch(`https://confidental1.com/api/patients/${patientId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          const data = await response.json();
          setError(`Error: ${data.error}`);
          setLoading(false);
          return;
        }

        const data = await response.json();
        setPatientData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los datos del paciente:', error);
        setError('Error al obtener los datos del paciente.');
        setLoading(false);
      }
    };

    fetchPatientData();
  }, [patientId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPatientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://confidental1.com/api/patients/${patientId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(patientData),
      });

      if (!response.ok) {
        const data = await response.json();
        alert(`Error: ${data.error}`);
        return;
      }

      alert('Paciente actualizado con éxito.');
      navigate('/profile');
    } catch (error) {
      console.error('Error al actualizar el paciente:', error);
      alert('Error al actualizar el paciente.');
    }
  };

  if (loading) {
    return <Typography>Cargando datos del paciente...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Editar Paciente
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Nombre"
          name="name"
          value={patientData.name || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Edad"
          name="age"
          type="number"
          value={patientData.age || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Fecha de Nacimiento"
          name="birthDate"
          type="date"
          value={patientData.birthDate ? new Date(patientData.birthDate).toISOString().substring(0, 10) : ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Dirección"
          name="address"
          value={patientData.address || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Teléfono"
          name="phone"
          value={patientData.phone || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Observaciones"
          name="observations"
          value={patientData.observations || ''}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Guardar Cambios
        </Button>
        <Button variant="contained" color="secondary" sx={{ mt: 2, ml: 2 }} onClick={() => navigate('/profile')}>
          Cancelar
        </Button>
      </form>
    </Box>
  );
};

export default EditPatient;
