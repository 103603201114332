import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Box,
    Button,
    Typography,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    RadioGroup,
    FormControlLabel,
    Radio,
    Modal,
    Slider,
    Select,
    MenuItem,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SignatureCanvas from 'react-signature-canvas';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate } from 'react-router-dom';



const HistoriaClinicaPersonal = () => {
    const { patientId } = useParams();
    const [savedForms, setSavedForms] = useState([]);
    const [expandedFormId, setExpandedFormId] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [savedResponses, setSavedResponses] = useState([]);
    const [isDrawingEnabled, setIsDrawingEnabled] = useState(false);
    const [showResponses, setShowResponses] = useState([]);
    const [penColor, setPenColor] = useState('#000000');
    const [penSize, setPenSize] = useState(5);
    const canvasRef = useRef(null);
    const imageRef = useRef([]);
    const combinedCanvasRef = useRef(null);
    const ctxRef = useRef(null);
    const isDrawingRef = useRef(false);
    const sigCanvasRef = useRef([]);
    const [isSigning, setIsSigning] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [expandedResponseId, setExpandedResponseId] = useState(null); // Nuevo estado para las respuestas guardadas

    const handleSelectResponse = (formId) => {
        setExpandedResponseId(formId === expandedResponseId ? null : formId);
        handleShowResponse(formId); // Asegúrate de cargar las respuestas cuando el Accordion se expanda
    };

    // Fetch de formularios
    const fetchForms = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('No se encontró el token. Por favor, inicia sesión.');
            return;
        }

        try {
            const response = await fetch(`https://confidental1.com/api/formularios/patient/${patientId}/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                setError('Error al cargar los formularios');
                return;
            }

            const data = await response.json();
            setSavedForms(data);
        } catch (error) {
            setError('Error al conectarse con el servidor');
        }
    }, [patientId]);

    // Fetch de respuestas guardadas
    const fetchSavedResponses = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('No se encontró el token. Por favor, inicia sesión.');
            return;
        }
        try {
            const response = await fetch(`https://confidental1.com/api/formularios/patient/${patientId}/answered`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setSavedResponses(data);
            } else {
                setError('Error al cargar las respuestas guardadas');
            }
        } catch (error) {
            setError('Error al conectarse con el servidor');
        }
    }, [patientId]); // Agregar patientId como dependencia

    // Llamar a los fetch al cargar el componente
    useEffect(() => {
        if (!patientId) {
            setError('El ID del paciente no está disponible o es inválido.');
            return;
        }
        fetchForms();
        fetchSavedResponses();
    }, [patientId, fetchForms, fetchSavedResponses]);

    // Manejar selección de formulario
    const handleSelectForm = (formId) => {
        setExpandedFormId(formId === expandedFormId ? null : formId);
        const selectedForm = savedForms.find((form) => form._id === formId);
        if (selectedForm) {
            setAnswers(Array(selectedForm.questions.length).fill(''));
            imageRef.current = Array(selectedForm.questions.length).fill(null); // Asegurar que la referencia de imágenes se limpie para cada formulario
        }
    };

    const handleAnswerChange = (index, value) => {
        const newAnswers = [...answers];
        newAnswers[index] = value;
        setAnswers(newAnswers);
    };

    // Captura de firma
    const handleSignatureCapture = (index) => {
        const signatureDataUrl = sigCanvasRef.current[index].getTrimmedCanvas().toDataURL('image/png');
        
        handleAnswerChange(index, signatureDataUrl);
    };

    // Indicador para saber si el canvas fue modificado
    let isCanvasModified = false;

    // Dibujar en el canvas
    const handleCanvasMouseDown = (e) => {
        if (!isDrawingEnabled || !ctxRef.current) return;
        isDrawingRef.current = true;
        isCanvasModified = true;
        const canvas = canvasRef.current;
        const ctx = ctxRef.current;
        const rect = canvas.getBoundingClientRect();

        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        const x = (e.clientX - rect.left) * scaleX;
        const y = (e.clientY - rect.top) * scaleY;

        ctx.beginPath();
        ctx.moveTo(x, y);
    };

    const handleCanvasMouseUp = () => {
        isDrawingRef.current = false;
    };

    const handleCanvasMouseMove = (e) => {
        if (!isDrawingRef.current || !isDrawingEnabled || !ctxRef.current) return;
        const canvas = canvasRef.current;
        const ctx = ctxRef.current;
        const rect = canvas.getBoundingClientRect();

        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        const x = (e.clientX - rect.left) * scaleX;
        const y = (e.clientY - rect.top) * scaleY;

        ctx.lineTo(x, y);
        ctx.strokeStyle = penColor;
        ctx.lineWidth = penSize;
        ctx.lineCap = 'round';
        ctx.stroke();
    };

    // Soporte para pantallas táctiles
    const handleCanvasTouchStart = (e) => {
        if (!isDrawingEnabled || !ctxRef.current) return;
        const touch = e.touches[0];
        handleCanvasMouseDown({ clientX: touch.clientX, clientY: touch.clientY });
    };

    const handleCanvasTouchMove = (e) => {
        if (!isDrawingEnabled || !ctxRef.current) return;
        const touch = e.touches[0];
        handleCanvasMouseMove({ clientX: touch.clientX, clientY: touch.clientY });
        e.preventDefault(); // Evitar el desplazamiento de la página mientras se dibuja
    };

    const handleCanvasTouchEnd = () => {
        handleCanvasMouseUp();
    };

    const handleImageLoad = (index) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const img = imageRef.current[index];

        if (img) {
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;

            ctxRef.current = ctx;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        }
    };

    // Combinar imagen y canvas
    const combineCanvasWithImage = () => {
        const combinedCanvas = combinedCanvasRef.current;
        const ctxCombined = combinedCanvas.getContext('2d');
        const imgElement = imageRef.current[0];
        const drawingCanvas = canvasRef.current;

        if (imgElement) {
            combinedCanvas.width = imgElement.naturalWidth;
            combinedCanvas.height = imgElement.naturalHeight;

            ctxCombined.drawImage(imgElement, 0, 0, combinedCanvas.width, combinedCanvas.height);
            ctxCombined.drawImage(drawingCanvas, 0, 0, combinedCanvas.width, combinedCanvas.height);
        }

        return combinedCanvas.toDataURL('image/png');
    };

    // Manejar el envío del formulario
    const handleSubmit = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('No se encontró el token. Inicia sesión.');
            return;
        }

        let combinedImage = null;
        if (canvasRef.current && imageRef.current && isCanvasModified) {
            combinedImage = combineCanvasWithImage();
        }

        const updatedAnswers = [...answers];
        if (combinedImage) {
            updatedAnswers.push(combinedImage);
        }

        const formData = {
            formId: expandedFormId,
            answers: updatedAnswers,
            patientId,
        };

        try {
            const response = await fetch('https://confidental1.com/api/formularios/submit-answers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                alert('Error al guardar las respuestas');
            } else {
                setSavedResponses((prevResponses) => [...prevResponses, formData.answers]);
                setAnswers([]);
                setExpandedFormId(null);
                await fetchForms();
                await fetchSavedResponses();
                isCanvasModified = false;
                alert('Respuestas guardadas exitosamente');
            }
        } catch (error) {
            setError('Error al enviar las respuestas');
        }
    };

    // Mostrar respuestas guardadas
    const handleShowResponse = async (formId) => {
        const responsesForForm = savedResponses.filter((response) => response.formId === formId);
        const selectedForm = savedForms.find((form) => form._id === formId);
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        }
        setShowResponses(
            responsesForForm.length > 0
                ? responsesForForm.map((response) => ({
                    questions: selectedForm.questions,
                    answers: response.answers,
                }))
                : []
        );
    };

    const renderAnswer = (answer) => {
        if (typeof answer === 'string' && answer.startsWith('data:image/png;base64,')) {
            return <img src={answer} alt="Imagen respuesta" style={{ width: '100%', maxWidth: '500px' }} />;
        }
        return <Typography variant="body1">{answer}</Typography>;
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Historia Clínica Personal
            </Typography>
            <Button
                variant="outlined"
                startIcon={<PrintIcon />}
                sx={{
                    mt: 1,
                    color: 'orange',
                    borderColor: 'orange',
                    '&:hover': {
                        backgroundColor: 'orange',
                        color: 'white',
                        borderColor: 'orange',
                    },
                }}
                onClick={() => navigate(`/imprimir/${patientId}`)}
            >
                Imprimir Formularios
            </Button>

            {error && <Typography color="error">{error}</Typography>}

            <Box sx={{ mb: 4 }}>
                <Typography variant="h5">Formularios Guardados</Typography>
                {savedForms.length === 0 ? (
                    <Typography>No hay formularios guardados.</Typography>
                ) : (
                    savedForms.map((form, formIndex) => (
                        <Accordion
                            key={form._id}
                            expanded={expandedFormId === form._id}
                            onChange={() => handleSelectForm(form._id)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6">{form.title || 'Formulario sin título'}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    {form.questions.map((question, qIndex) => (
                                        <Box key={qIndex} sx={{ mb: 2 }}>
                                            <Typography variant="body1">{question.text}</Typography>

                                            {/* Solo renderizar el cuadro de respuesta si no es de tipo 'text-only' */}
                                            {question.type !== 'text-only' && (
                                                <>
                                                    {question.type === 'multiple' ? (
                                                        <RadioGroup
                                                            name={`question-${qIndex}`}
                                                            value={answers[qIndex] || ''}
                                                            onChange={(e) => handleAnswerChange(qIndex, e.target.value)}
                                                        >
                                                            {question.options.map((option, oIndex) => (
                                                                <FormControlLabel
                                                                    key={oIndex}
                                                                    value={option}
                                                                    control={<Radio />}
                                                                    label={option}
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    ) : question.type === 'signature' ? (
                                                        <Box>
                                                            <SignatureCanvas
                                                                penColor="black"
                                                                canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                                                ref={(el) => (sigCanvasRef.current[qIndex] = el)}
                                                                onEnd={() => handleSignatureCapture(qIndex)}
                                                            />
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                sx={{ mt: 1 }}
                                                                onClick={() => {
                                                                    sigCanvasRef.current[qIndex].clear();  // Limpiar la firma del canvas
                                                                    handleAnswerChange(qIndex, '');        // Resetear el valor de la firma en las respuestas
                                                                }}
                                                            >
                                                                Limpiar Firma
                                                            </Button>
                                                            {answers?.[qIndex] && (
                                                                <Typography sx={{ mt: 1, color: 'green' }}>
                                                                    Firma capturada. Puedes limpiar y volver a firmar si es necesario.
                                                                </Typography>
                                                            )}
                                                        </Box>

                                                    ) : question.type === 'image' && expandedFormId === form._id ? (
                                                        <Box
                                                            sx={{
                                                                position: 'relative',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                maxWidth: '100%',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            <img
                                                                ref={(el) => (imageRef.current[qIndex] = el)}
                                                                src={question.image}
                                                                alt={`Pregunta ${qIndex + 1}`}
                                                                style={{
                                                                    maxWidth: '100%',
                                                                    height: 'auto',
                                                                }}
                                                                onLoad={() => handleImageLoad(qIndex)}
                                                            />
                                                            <canvas
                                                                ref={canvasRef}
                                                                style={{
                                                                    border: '1px solid #000',
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                                onMouseDown={handleCanvasMouseDown}
                                                                onMouseUp={handleCanvasMouseUp}
                                                                onMouseMove={handleCanvasMouseMove}
                                                                onTouchStart={handleCanvasTouchStart}
                                                                onTouchMove={handleCanvasTouchMove}
                                                                onTouchEnd={handleCanvasTouchEnd}
                                                            />
                                                            <canvas ref={combinedCanvasRef} style={{ display: 'none' }} />
                                                        </Box>
                                                    ) : (
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            value={answers[qIndex] || ''}
                                                            onChange={(e) => handleAnswerChange(qIndex, e.target.value)}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    ))}

                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))
                )}
            </Box>

            {/* Controles de dibujo y botones ajustados */}
            <Box
                sx={{
                    mt: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                {isDrawingEnabled && (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Select value={penColor} onChange={(e) => setPenColor(e.target.value)}>
                            <MenuItem value="#000000">Negro</MenuItem>
                            <MenuItem value="#ff0000">Rojo</MenuItem>
                            <MenuItem value="#0000ff">Azul</MenuItem>
                            <MenuItem value="#00ff00">Verde</MenuItem>
                        </Select>
                        <Slider
                            value={penSize}
                            min={1}
                            max={20}
                            step={1}
                            onChange={(e, newValue) => setPenSize(newValue)}
                            aria-labelledby="pen-size-slider"
                            sx={{ width: { xs: '100%', sm: 150 } }}
                        />
                    </Box>
                )}

                <Button variant="contained" sx={{ mt: 2, width: '100%' }} onClick={() => setIsDrawingEnabled(!isDrawingEnabled)}>
                    {isDrawingEnabled ? 'Desactivar Dibujo' : 'Activar Dibujo'}
                </Button>

                {expandedFormId && (
                    <Button variant="contained" color="primary" sx={{ mt: 2, width: '100%' }} onClick={handleSubmit}>
                        Guardar Respuestas
                    </Button>
                )}
            </Box>


            <Box sx={{ mt: 4 }}>
                <Typography variant="h5">Respuestas Guardadas</Typography>
                {savedForms.length === 0 ? (
                    <Typography>No hay respuestas guardadas.</Typography>
                ) : (
                    savedForms.map((form) => (
                        <Accordion
                            key={form._id}
                            expanded={expandedResponseId === form._id}
                            onChange={() => handleSelectResponse(form._id)} // Cambiado a handleSelectResponse
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6">{form.title || 'Formulario sin título'}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {showResponses.length > 0 ? (
                                    showResponses.map((response, responseIndex) => (
                                        <Box 
                                key={responseIndex} 
                                sx={{ 
                                    mb: 4, 
                                    backgroundColor: responseIndex % 2 === 0 ? '#f5f5f5' : '#e0e0e0', // Alterna colores de fondo
                                    padding: 2, 
                                    borderRadius: 2 
                                }}
                            >
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        backgroundColor: responseIndex % 2 === 0 ? '#1976d2' : '#1e88e5', 
                                        color: 'white', 
                                        padding: 1, 
                                        borderRadius: 1 
                                    }}
                                >
                                    {responseIndex + 1}
                                </Typography>
                                            {Array.isArray(response.answers) ? (
                                                response.answers.map((answer, index) => (
                                                    <Box key={index} sx={{ mb: 2 }}>
                                                        {/* Mostrar la pregunta correspondiente */}
                                                        {response.questions && response.questions[index] && (
                                                            <Typography variant="body1">
                                                                <strong>{response.questions[index].text}</strong>
                                                            </Typography>
                                                        )}
                                                        {/* Mostrar la respuesta */}
                                                        {renderAnswer(answer, response.questions[index]?.type)}
                                                    </Box>
                                                ))
                                            ) : (
                                                <Typography>No hay respuestas disponibles.</Typography>
                                            )}
                                        </Box>
                                    ))
                                ) : (
                                    <Typography>No hay respuestas disponibles.</Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))
                )}
            </Box>


            <Modal
                open={isSigning}
                onClose={() => setIsSigning(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: '8px' }}>
                    <Typography variant="h6" gutterBottom>
                        Firma aquí
                    </Typography>
                    <SignatureCanvas
                        penColor="black"
                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                        ref={sigCanvasRef}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <Button variant="contained" onClick={() => sigCanvasRef.current.clear()}>
                            Limpiar
                        </Button>
                        <Button variant="contained" color="primary">
                            Guardar Firma
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default HistoriaClinicaPersonal;






