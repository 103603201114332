import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';

const FloatingWhatsAppButton = () => {
  const whatsappLink = "https://wa.me/524493464846"; // Enlace de WhatsApp al número específico
  const [showMessage, setShowMessage] = useState(false); // Estado para mostrar/ocultar el mensaje

  useEffect(() => {
    // Alternar el estado de showMessage cada 5 segundos
    const interval = setInterval(() => {
      setShowMessage(prev => !prev);
    }, 3000);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 40, // Distancia desde la parte inferior de la pantalla
        right: 40,  // Distancia desde la derecha de la pantalla
        zIndex: 1000,  // Asegura que esté por encima de otros elementos
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {/* Mensaje que se despliega desde el lado izquierdo */}
      <Slide direction="left" in={showMessage} mountOnEnter unmountOnExit>
        <Typography
          variant="body1"
          sx={{
            mr: 2,
            p: 1,
            bgcolor: '#25D366', // Mismo color que el botón de WhatsApp
            color: 'white',
            borderRadius: 2,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
          }}
        >
          ¿Le puedo ayudar en algo?
        </Typography>
      </Slide>

      {/* Botón de WhatsApp */}
      <IconButton
        color="primary"
        href={whatsappLink}
        target="_blank"
        sx={{
          bgcolor: '#25D366',  // Color de fondo de WhatsApp
          color: 'white',
          '&:hover': {
            bgcolor: '#1EBE52',
          },
          width: 56,  // Tamaño del botón
          height: 56,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.8)', // Añade sombra para destacar el botón
        }}
      >
        <WhatsAppIcon sx={{ fontSize: 45 }} /> {/* Tamaño del icono */}
      </IconButton>
    </Box>
  );
};

export default FloatingWhatsAppButton;


